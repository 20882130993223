import { render } from "@testing-library/react";
import react from "react";
import './Header.css';
import {Route, Switch, NavLink} from "react-router-dom";

// import all images
import logo from './assets/logoo.png';





function Header() {
    return(

<div className="container">
<div className="row">
<nav className="navbar">
<div className="container">
  <a className="navbar-brand" href="#">
  <img src={logo}  alt="Captech" /> 
  </a>
  
  <div className="">
      <ul className="mainmenu" >   
        <li><NavLink className="active" to="/">Home</NavLink></li>
        <li><NavLink to="/about">About us</NavLink></li>
        <li><NavLink to="/gc">General Contractor</NavLink></li>
        <li><NavLink to="/lc/src/0">Sub Contractor Registration</NavLink></li>
        <li><NavLink to="/media">Media</NavLink></li>
        <li><NavLink to="/career">Career</NavLink></li>
        <li><NavLink to="/Contact">Contact Us</NavLink></li>
        {/* <li><NavLink to="/blog">Blogs</NavLink></li> */}
        {/* <li><a href="#" data-toggle="dropdown">==</a></li> */}
        <li></li>

      </ul>
   </div>
</div>
</nav>
</div>
</div>
        
    
        )
}

export default Header;