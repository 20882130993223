import React, { useState } from "react";
import axios from "axios";
import "./Real2.css";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payment from "./PaymentForm";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const schema = yup.object().shape({
  company_name: yup.string().required(),
  company_entity: yup.string().required(),
  reg_address: yup.string().required(),
  pan_no: yup
    .string()
    .required("PAN No is a required field")
    .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Please Enter The Correct PAN No"),
  gst_no: yup
    .string()
    .required("GST No is a required field")
    .matches(
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
      "Please Enter The Correct GST No "
    ),
  //specialization: yup.object().required(),
  kharchi_credit_period: yup
    .string() 
    .max(3,"Please Enter valid days")
    .required("This Filed is Required")
    .matches(
      /^([1-9]|[1-9][1-9]|[1-3][0-6][0-5])$/,
      "Please Enter The Correct days"
    ),
  // kharchi_credit_period: yup.string().min(1).max(3).required(),
  ra_bill_credit_period: yup 
  .string() 
  .max(3,"Please Enter valid days")
  .required("This Filed is Required")
  .matches(
    /^([1-9]|[1-9][1-9]|[1-3][0-6][0-5])$/,
    "Please Enter The Correct days"
  ),
  project_name: yup.string().required(),
  project_type: yup.string().required(),
  project_address: yup.string().required(),
  site_no_storeys: yup 
  .string() 
  .min(0,"Please Enter Valid Number Of Storeys")
  .max(3,"Please Enter Valid Number Of Storeys")
  .required("This Filed is Required")
  // .matches(
  //   /[3-9]\d\d/,
  //   "Please Enter The Correct days"
  // )
  , 
  project_current_status: yup.string().required(),
  level_completed: yup.string().required(),
  // labour_camp_img: yup.string().required(),......
  // logistics_plan_site: yup.string().required(),....
  additional_info: yup.string(),
  project_incharge_name: yup.string().required(),
  project_inch_mobile: yup
  .string() 
  .required("Number is required")
  .max(10,"Please enter a valid number")
  .min(10,"Please enter a valid number")
  .matches(
    /[3-9]\d\d/,
    "Must be a valid number"
  ),
  project_inch_email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Company Email Address is required"),
  // project_inch_email: yup.string().email().required(),
  acceptTerms: yup.bool().oneOf([true], "Accept Ts & Cs is required"),
  acceptMsg: yup.bool().oneOf([true], "Accept Ts & Cs is required"),
  activity: yup.string().required(),
  labour_count: yup.string().required(),
  quantity_work: yup.string().required(),
  unit: yup.string().required(),
  workfront_duration: yup.string().required(),
  unit_e: yup.string().required(),
  utr_no: yup.string().required(),
  referral_name: yup.string(),
  referral_code: yup.string(),
  // .required(" Referral code is a required Field")
  // .matches(/^[a-zA-Z0-9]{10,}$/, "Please enter a Valid Referral Code"),
});
const works = [
  "Aluminium door and window work",
  "Brick/Block/Partition wall work",
  "Concrete work",
  "Dismantling & Demolishing",
  "Drainage and Landscaping work",
  "Earth Work",
  "Electrical works",
  "Fire Fighting work",
  "Flooring work",
  "Horticulture",
  "HVAC system work",
  "IT and Telecommunication system",
  "Lifts & escalators",
  "Painting work",
  "Piling work",
  "Plastering and putty work",
  "Plumbing and sanitation work",
  "Reinforcement work",
  "Road and Pavement work",
  "Roofing and ceiling work",
  "Safety",
  "Shuttering work",
  "Structural steel work",
  "Unskilled",
  "Water proofing work",
  "Wood work",
];

const quantityUnits = [
  "brass",
  "cuft",
  "cum",
  "ft",
  "kg",
  "km",
  "m",
  "MT",
  "Nos",
  "sqft",
  "sqm",
];
const levels = [
  " Level 1",
  "Level 2",
  "Level 3",
  "Level 4",
  "Level 5",
  "Level 6",
  "Level 7",
  "Level 8",
  "Level 9",
  "Level 10",
  "Level 11",
  "Level 12",
  "Level 13",
  "Level 14",
  "Level 15",
  "Level 16",
  "Level 17",
  "Level 18",
  "Level 19",
  "Level 20",
  "Level 21",
  "Level 22",
  "Level 23",
  "Level 24",
  "Level 25",
  "Level 26",
  "Level 27",
  "Level 28",
  "Level 29",
  "Level 30",
  "Level 31",
  "Level 32",
  "Level 33",
  "Level 34",
  "Level 35",
  "Level 36",
  "Level 37",
  "Level 38",
  "Level 39",
  "Level 40",
  "Level 41",
  "Level 42",
  "Level 43",
  "Level 44",
  "Level 45",
  "Level 46",
  "Level 47",
  "Level 48",
  "Level 49",
  "Level 50",
];
const durationUnits = ["Days", "Months", "Weeks", "Years"];

const Background = () => {
  return (
    <>
      <div className="background" style={{ position: "relative" }}>
        <h3
          style={{
            // maxWidth: "50%",
            // textAlign: "center",
            // margin: "0 auto",
            padding: "70px 50px",
            fontSize: "40px",
            textShadow: "-1px 1px 1px rgb(255 255 255 / 60%)",
            color: "#ffffff",
            fontWeight: 600,
          }}
        >
          Registration for General Contractor - <br />
          (Real Estate)
        </h3>
      </div>
    </>
  );
};
const Msg = ({ closeToast }) => (
  <div>
    <div style={{ textAlign: "center" }}>
      <h3 style={{ color: "#f47d35" }}>Thank you for your registration.</h3>
      <br />
      <h4 style={{ color: "" }}>We will get back to you in 24 hours</h4>
      <br />
      <h5 style={{ color: "#000" }}>For further assistance </h5>
      <h6 style={{ color: "f47d35" }}>18002667037</h6>
      <h6 style={{ color: "f47d35" }}>helpdesk@cap-tech.in</h6>
    </div>
  </div>
);
function Real2() {
  const [formdata, setFormdata] = useState();
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", resolver: yupResolver(schema) });

  const submitForm = (data) => {
    setFormdata(data);

    const {
      activity,
      labour_count,
      quantity_work,
      unit,
      workfront_duration,
      unit_e,
      Fitout,
      GatedCommunities,
      Hospital,
      Hotel,
      Industrial,
      Institutional,
      Mall,
      Other,
      Residential,
      Villaments,
    } = data;

    const specialisation = {
      Fitout,
      GatedCommunities,
      Hospital,
      Hotel,
      Industrial,
      Institutional,
      Mall,
      Other,
      Residential,
      Villaments,
    };

    const requirement_array = [
      {
        activity,
        labour_count: parseInt(labour_count),
        quantity_work: parseFloat(quantity_work),
        unit,
        unit_e,
        workfront_duration: parseInt(workfront_duration),
      },
    ];

    Object.keys(data).forEach((item) => {
      for (let i = 2; i <= 4; i++) {
        if (item === `activity${i}` && data[item]) {
          requirement_array.push({
            activity: data[`activity${i}`],
            labour_count: parseInt(data[`labour_count${i}`]),
            quantity_work: parseFloat(data[`quantity_work${i}`]),
            unit: data[`unit${i}`],
            unit_e: data[`unit_e${i}`],
            workfront_duration: parseInt(data[`workfront_duration${i}`]),
          });
        }
      }
    });

    const readyData = new FormData();
    Object.keys(data).forEach((item) => readyData.append(item, data[item]));

    readyData.delete("labour_camp_img");
    readyData.delete("logistics_plan_site");

    for (let i = 0; i < data.labour_camp_img.length; i++) {
      readyData.append("labour_camp_img", data.labour_camp_img[i]);
    }
    for (let i = 0; i < data.logistics_plan_site.length; i++) {
      readyData.append("logistics_plan_site", data.logistics_plan_site[i]);
    }
    const specialisation_data = [];
    Object.keys(specialisation).forEach((item) => {
      if (specialisation[item] === "true") {
        specialisation_data.push(item);
      }
    });

    readyData.append("payment_amount", 28320);

    readyData.append("requirements", JSON.stringify(requirement_array));

    readyData.append("specialization", JSON.stringify(specialisation_data));

    const url = "https://generalcontractor.herokuapp.com/api/realEstateForm";
    axios
      .post(url, readyData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast(<Msg />);
          reset();
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      reset( );
    //
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
  };

  return (
    <>
      <div className="App">
        <div className="container py-5">
          <Background />
          <form onSubmit={handleSubmit(submitForm)}>
            {/* New section of refferal started  */}
            <section className="w-100 border-0 shadow p-4 text-black mx-auto">
              <h3 className="panel-heading">Referral</h3>
              <section
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                  <div className="form-group ">
                    <label htmlFor="name" required>
                      Name of the Referral{" "}
                    </label>
                    <input
                    style={{width:'350px'}}
                      type="text"
                      className="form-control  "
                      id="name"
                      name="referral_name"
                      placeholder="Enter referral Name"
                      {...register("referral_name", { required: false })}
                    />
                    {errors.referral_name && (
                      <span style={{ color: "red" }}>
                        Name of Referral is a required Field
                      </span>
                    )}
                  </div> 
                  <div className="form-group ">
                    <label htmlFor="code" required>
                      Referral Code
                    </label>
                    <input
                    style={{width:'350px'}}
                      type="text"
                      className="form-control  "
                      maxlength="10"
                      id="code"
                      name="referral_code"
                      placeholder="Enter Company Name"
                      {...register("referral_code", {
                        required: false,
                        // pattern: {
                        //   value: /^[a-zA-Z0-9]{10,}$/,
                        //   message: "",
                        // },
                      })}
                    />
                    {errors.referral_code && (
                      <span style={{ color: "red" }}>
                        {/* Referral code is a required Field */}
                        {errors.referral_code.message}
                      </span>
                    )}
                  </div> 
              </section>
            </section>
            {/* referral section end */}
            <br />
            <br />
            <section className="w-100 border-0 shadow p-4 text-black mx-auto">
              <h3 className="panel-heading">Company Details</h3>
              <section
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                <div className="formContainer ">
                  <div className="form-group ">
                    <label htmlFor="name" required>
                      Company Name (as per PAN Card){" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="company_name"
                      placeholder="Enter Company Name"
                      {...register("company_name", { required: true })}
                    />
                    {errors.company_name && (
                      <span style={{ color: "red" }}>
                        Company Name is a required Field
                      </span>
                    )}
                  </div>
                  <br />

                  <div className="form-group">
                    <label htmlFor="select">
                      Entity <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      {...register("company_entity", { required: true })}
                    >
                      <option value="" selected disabled>
                        Please select
                      </option>
                      <option value="Limited Liability Partnership">
                        {" "}
                        Limited Liability Partnership
                      </option>
                      <option value="One Person Company">
                        {" "}
                        One Person Company
                      </option>
                      <option value="Partnership"> Partnership</option>
                      <option value="Private Limited Company">
                        {" "}
                        Private Limited Company
                      </option>
                      <option value="Public Limited Company">
                        {" "}
                        Public Limited Company
                      </option>
                      <option value="Sole Proprietorship">
                        Sole Proprietorship
                      </option>
                    </select>
                    {errors.company_entity && (
                      <span style={{ color: "red" }}>
                        Entity is a required Field
                      </span>
                    )}
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="name">
                      Registered Addres <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="registeredAddres"
                      name="reg_address"
                      rows={9}
                      placeholder="Enter Company Addres"
                      {...register("reg_address", { required: true })}
                    />
                    {errors.reg_address && (
                      <span style={{ color: "red" }}>
                        Address is a required field
                      </span>
                    )}
                  </div>
                  <br />
                </div>
                <div className="formContainer ">
                  <div className="form-group">
                    <label htmlFor="select">
                      Specialization <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle
                        caret
                        style={{
                          background: "#fff",
                          border: "1px solid #c9c9c9",
                          color: "#c9c9c9",
                          // width:'350px'
                        }}
                      >
                        Select Specialisations
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Residential", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Residential
                            </label>
                          </div>
                        </DropdownItem>{" "}
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Hotel", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Hotel
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Hospital", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Hospital
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          {" "}
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Industrial", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Industrial
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Institutional", {
                                required: false,
                              })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Institutional
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Mall", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Mall
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("GatedCommunities", {
                                required: false,
                              })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              GatedCommunities
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Villaments", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Villaments
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Fitout", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Fitout
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          {" "}
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                              {...register("Other", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Other
                            </label>
                          </div>
                        </DropdownItem>
                        <DropdownItem divider />
                      </DropdownMenu>
                    </ButtonDropdown>
                    {errors.specialization && (
                      <span style={{ color: "red" }}>
                        Specialization is a required Field
                      </span>
                    )}
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="name">
                      PAN No <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="panNo"
                      placeholder="Enter PAN No"
                      maxlength="10"
                      {...register("pan_no", {
                        required: true,
                        pattern: {
                          value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                          message: "",
                        },
                      })}
                    />
                    {errors.pan_no && (
                      <span style={{ color: "red" }} role="alert">
                        {errors.pan_no.message}
                      </span>
                    )}
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="name">
                      GST No <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="gst_no"
                      maxlength="15"
                      name="gst_no"
                      placeholder="Enter GST No"
                      {...register("gst_no", {
                        required: true,
                        pattern: {
                          value:
                            /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
                          message: "",
                        },
                      })}
                    />
                    {errors.gst_no && (
                      <span style={{ color: "red" }} role="alert">
                        {errors.gst_no.message}
                      </span>
                    )}
                  </div>
                  <br />
                  {/* <div className="form-group ">
                    <label htmlFor="select">Specialization *</label>
                    <Multiselect
                      {...register("Specialization", { required: true })}
                      isObject={false}
                      onRemove={function noRefCheck() {}}
                      onSearch={function noRefCheck() {}}
                      onSelect={function noRefCheck() {}}
                      options={[
                        "Residential",
                        "Hospital ",
                        "Industrial ",
                        "Institutional ",
                        "Mall ",
                        "Gated Communities ",
                        "Villaments ",
                        "Fitout ",
                        "Option "
                      ]}
                    />
                    {errors.Specialization && (
                      <span style={{ color: "red" }}>
                        This Field is required
                      </span>
                    )}
                  </div> 
                  <br />*/}
                  <div className="form-group">
                    <label htmlFor="name">
                      Kharchi Credit Period (days)
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="kharchi_credit_period"
                      name="kharchi_credit_period"
                      placeholder="Enter Kharchi Credit Period"
                       {...register("kharchi_credit_period", {
                        required: true,
                        pattern: {
                          value:
                          /^([1-9]|[1-9][1-9]|[1-3][0-6][0-5])$/,
                          message: "",
                        },
                      })}
                    />
                    {errors.kharchi_credit_period && (
                      <span style={{ color: "red" }} role="alert">
                        {errors.kharchi_credit_period.message}
                      </span>
                    )}
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="name">
                      RA Bill Credit Period (days)
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="ra_bill_credit_period"
                      name="ra_bill_credit_period"
                      placeholder="Enter RA Bill Period"
                      {...register("ra_bill_credit_period", {
                        required: true,
                        pattern: {
                          value:
                            /^([1-9]|[1-9][1-9]|[1-3][0-6][0-5])$/,
                          message: "",
                        },
                      })}
                    />
                    {errors.ra_bill_credit_period && (
                      <span style={{ color: "red" }} role="alert">
                        {errors.ra_bill_credit_period.message}
                      </span>
                    )}
                  </div>
                  <br />
                </div>
              </section>
            </section> 
            <br />
            <br />
            <br />
            <section
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <section
                // style={{ width: "45%" }}
                className="formContainer card border-0 shadow  text-black p-4  "
              >
                <h3 className="panel-heading">Project Details</h3>
                <div className="form-group">
                  <label htmlFor="projectName">
                    Name of the Project to be registered{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="project_name"
                    name="project_name"
                    placeholder="Enter Project Name"
                    {...register("project_name", { required: true })}
                  />
                  {errors.project_name && (
                    <span style={{ color: "red" }}>
                      Project Name is required
                    </span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="select">
                    Project Type <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("project_type", { required: true })}
                  >
                    <option value="" selected disabled>
                      Please select
                    </option>
                    <option value="Mall">Mall</option>
                    <option value="GatedCommunitites">
                      Gated Communitites
                    </option>
                    <option value="Fitout">Fitout</option>
                    <option value="Industrial">Industrial</option>
                    <option value="Institution">Institution</option>
                    <option value="Villament">Villament</option>
                    <option value="Hospital">Hospital</option>
                    <option value="Hotel">Hotel </option>
                    <option value="Commercial"> Commercial</option>
                    <option value="Others">Others </option>
                  </select>
                  {errors.project_type && (
                    <span style={{ color: "red" }}>
                      Project Type is required
                    </span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Project Address <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    defaultValue={""}
                    placeholder="Enter Project Address"
                    {...register("project_address", { required: true })}
                  />
                  {errors.project_address && (
                    <span style={{ color: "red" }}>
                      Project Address is required
                    </span>
                  )}
                </div>
                <br />
                <h3 className="panel-heading">
                  Project Incharge / Subordinate
                </h3>
                <div className="form-group">
                  <label htmlFor="name">
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="project_incharge_name"
                    placeholder="Enter Project Incharge Name"
                    {...register("project_incharge_name", { required: true })}
                  />
                  {errors.project_incharge_name && (
                    <span style={{ color: "red" }}>
                      Project Incharge Name is required
                    </span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="name">
                    Mobile No <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="project_inch_mobile"
                    placeholder="Enter Mobile No"
                    {...register("project_inch_mobile", {
                      required: true,
                      pattern: {
                        value:
                        /[3-9]\d\d/,
                        message: "",
                      },
                    })}
                  />
                  {errors.project_inch_mobile && (
                    <span style={{ color: "red" }} role="alert">
                      {errors.project_inch_mobile.message}
                    </span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="name">
                    Company Email Address{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="project_inch_email"
                    placeholder="Enter Company email Address"
                    {...register("project_inch_email", {
                      required: true,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: " ",
                      },
                    })}
                  />
                  {errors.project_inch_email && (
                    <span role="alert" style={{ color: "red" }}>
                      {errors.project_inch_email.message}
                    </span>
                  )}
                </div>
                <br />
              </section>
              <section
                // style={{ width: "45%" }}
                className="formContainer card border-0 shadow  text-black p-4  "
              >
                <h3 className="panel-heading">Site Details</h3>
                <div className="form-group">
                  <label htmlFor="site_no_storeys">No Of Storeys</label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <input
                    type="number"
                    className="form-control"
                    id="site_no_storeys"
                    placeholder="Enter No of Storeys"
                    {...register("site_no_storeys", {
                      required: true,
                      pattern: {
                        value:
                        /[3-9]\d\d/,
                        message: "",
                      },
                    })}
                  />
                  {errors.site_no_storeys && (
                    <span style={{ color: "red" }} role="alert">
                      {errors.site_no_storeys.message}
                    </span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="select">Project Current Status</label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <select
                    className="form-select"
                    {...register("project_current_status", { required: true })}
                  >
                    <option value="" selected disabled>
                      Please select
                    </option>
                    <option value="subStructure">Sub Structure</option>
                    <option value="SuperStructure"> Super Structure </option>
                  </select>
                  {errors.project_current_status && (
                    <span style={{ color: "red" }}>
                      Project Current Status Field is required
                    </span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="select">Level Completed</label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <select
                    className="form-select"
                    {...register("level_completed", { required: true })}
                  >
                    <option value="" selected disabled>
                      Please select
                    </option>
                    <option value="Not Applicable"> Not Applicable</option>
                    <option value="Plain Land">Plain Land</option>
                    <option value="Excavation">Excavation</option>
                    <option value="Pcc">Pcc</option>
                    <option value="Foundation">Foundation</option>
                    <option value="Basement 3">Basement 3</option>
                    <option value="Basement 2">Basement 2</option>
                    <option value="Basement 1">Basement 1</option>
                    <option value="Ground / Plinth">Ground / Plinth</option>
                    {levels.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors.level_completed && (
                    <span style={{ color: "red" }}>
                      Level Completed Field is required
                    </span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label className="form-label" htmlFor="customFile">
                    Labour Camp images and sites images
                  </label>
                  <input
                    type="file"
                    multiple
                    className="form-control"
                    id="fileUpload customFile"
                    accept=".apng,.avif,.gif,.jpg, .jpeg, .jfif, .pjpeg, .pjp,.png,.svg,.webp"
                    {...register("labour_camp_img", { required: true })}
                  />
                  <small
                    id="passwordHelpBlock"
                    className="form-text text-muted"
                  >
                    Max 10 images of 10MB each allowed
                  </small>
                  {errors.labour_camp_img && (
                    <span style={{ color: "red" }}>Please Upload File</span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label className="form-label" htmlFor="customFile">
                    Logistitc Plan of site
                  </label>
                  <input
                    type="file"
                    multiple
                    className="form-control"
                    id="fileUpload customFile"
                    accept=".apng,.avif,.gif,.jpg, .jpeg, .jfif, .pjpeg, .pjp,.png,.svg,.webp"
                    {...register("logistics_plan_site", { required: true })}
                  />
                  <small
                    id="passwordHelpBlock"
                    className="form-text text-muted"
                  >
                    Max 10 images of 10MB each allowed
                  </small>
                  {errors.logistics_plan_site && (
                    <span style={{ color: "red" }}>Please Upload File</span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Additional Info (if any)
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    defaultValue={""}
                    {...register("additional_info", { required: false })}
                  />
                </div>
                <br />
              </section>
            </section>
            <br />
            <br />
            <br />
            {/* <SubContractorForm /> */}
            <section
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <section className="formContainer card border-0 shadow  text-black p-4  ">
                <h3 className="panel-heading">Requirement 1</h3>
                <div className="form-group">
                  <label>Activity</label> <br />
                  <select
                    className="form-select"
                    name="activity"
                    {...register("activity", { required: true })}
                  >
                    {" "}
                    <option value="" selected disabled>
                      Please select
                    </option>
                    {works.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors.activity && (
                    <span className="scf-form-error">Required</span>
                  )}
                </div>
                <br />
                <div className="form-group">
                  <label>Required Labour Count</label> <br />
                  <input
                    type="number"
                    className="form-control"
                    name="labour_count"
                    placeholder="Enter Labour Count"
                    {...register("labour_count", { required: true })}
                  />
                  {errors.labour_count && (
                    <span className="scf-form-error">Required</span>
                  )}
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Quantity of Work</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="quantity_work"
                      placeholder="Enter Quantity of Work"
                      {...register("quantity_work", { required: true })}
                    />
                    {errors.quantity_work && (
                      <span className="scf-form-error">Required</span>
                    )}
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="unit"
                      {...register("unit", { required: true })}
                    >
                      {" "}
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {quantityUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors.unit && (
                      <span className="scf-form-error">Required</span>
                    )}
                  </div>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Workfront Duration</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="workfront_duration"
                      placeholder="Enter Duration"
                      {...register("workfront_duration", { required: true })}
                    />
                    {errors.workfront_duration && (
                      <span className="scf-form-error">Required</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="durationUnit"
                      {...register("unit_e", { required: true })}
                    >
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {durationUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors.unit_e && (
                      <span className="scf-form-error">Required</span>
                    )}
                  </div>
                </div>
                <br />
                <br />
              </section>
              <section className="formContainer card border-0 shadow  text-black p-4  ">
                <h3 className="panel-heading">Requirement 2</h3>
                <div className="form-group">
                  <label>Activity</label> <br />
                  <select
                    className="form-select"
                    name="activity2"
                    {...register("activity2", { required: false })}
                  >
                    {" "}
                    <option value="" selected disabled>
                      Please select
                    </option>
                    {works.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <br />
                <div className="form-group">
                  <label>Required Labour Count</label> <br />
                  <input
                    type="number"
                    className="form-control"
                    name="labour_count2"
                    placeholder="Enter Labour Count"
                    {...register("labour_count2", { required: false })}
                  />
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Quantity of Work</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="quantity_work2"
                      placeholder="Enter Quantity of Work"
                      {...register("quantity_work2", { required: false })}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="unit2"
                      {...register("unit2", { required: false })}
                    >
                      {" "}
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {quantityUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Workfront Duration</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="workfront_duration2"
                      placeholder="Enter Duration"
                      {...register("workfront_duration2", { required: false })}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="unit_e2"
                      {...register("unit_e2", { required: false })}
                    >
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {durationUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>
            </section>
            <br />
            <br />
            <section
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <section className="formContainer card border-0 shadow  text-black p-4  ">
                <h3 className="panel-heading">Requirement 3</h3>
                <div className="form-group">
                  <label>Activity</label> <br />
                  <select
                    className="form-select"
                    name="activity3"
                    {...register("activity3", { required: false })}
                  >
                    {" "}
                    <option value="" selected disabled>
                      Please select
                    </option>
                    {works.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <br />
                <div className="form-group">
                  <label>Required Labour Count</label> <br />
                  <input
                    type="number"
                    className="form-control"
                    name="labour_count3"
                    placeholder="Enter Labour Count"
                    {...register("labour_count3", { required: false })}
                  />
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Quantity of Work</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="quantity_work3"
                      placeholder="Enter Quantity of Work"
                      {...register("quantity_work3", { required: false })}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="unit3"
                      {...register("unit3", { required: false })}
                    >
                      {" "}
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {quantityUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Workfront Duration</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="workfront_duration3"
                      placeholder="Enter Duration"
                      {...register("workfront_duration3", { required: false })}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="unit_e3"
                      {...register("unit_e3", { required: false })}
                    >
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {durationUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>
              <section className="formContainer card border-0 shadow  text-black p-4  ">
                <h3 className="panel-heading">Requirement 4</h3>
                <div className="form-group">
                  <label>Activity</label> <br />
                  <select
                    className="form-select"
                    name="activity4"
                    {...register("activity4", { required: false })}
                  >
                    {" "}
                    <option value="" selected disabled>
                      Please select
                    </option>
                    {works.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <br />
                <div className="form-group">
                  <label>Required Labour Count</label> <br />
                  <input
                    type="number"
                    className="form-control"
                    name="labour_count4"
                    placeholder="Enter Labour Count"
                    {...register("labour_count4", { required: false })}
                  />
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Quantity of Work</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="quantity_work4"
                      placeholder="Enter Quantity of Work"
                      {...register("quantity_work4", { required: false })}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="unit4"
                      {...register("unit4", { required: false })}
                    >
                      {" "}
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {quantityUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group">
                    <label>Workfront Duration</label> <br />
                    <input
                      type="number"
                      className="form-control"
                      name="workfront_duration4"
                      placeholder="Enter Duration"
                      {...register("workfront_duration4", { required: false })}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Unit</label> <br />
                    <select
                      className="form-select"
                      name="unit_e4"
                      {...register("unit_e4", { required: false })}
                    >
                      <option value="" selected disabled>
                        Please select
                      </option>
                      {durationUnits.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>
            </section>
            <br />
            <hr /> <br />
            <br />
            <div>
              {/* payment block */}

              <section className="w-100 border-0 shadow p-4 text-black mx-auto">
                <Payment />

                <div className="panel-heading payment-head">
                  {/* <h3>No of Project Registered</h3> */}
                  {/* <h3>Subscription</h3> */}
                  {/* <h3>Amount</h3> */}
                  <div className="form-group ">
                    <label htmlFor="name" style={{ color: "#fff" }}>
                      UTR No Is required
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="utr-no"
                      {...register("utr_no", { required: true })}
                    />
                    {errors.utr_no && (
                      <span style={{ color: "#fff" }}>UTR No is required</span>
                    )}
                    <p style={{ color: "#fff" }}>
                      Please copy UTR No at the time of Payment.
                    </p>
                  </div>
                  <div />
                </div>
              </section>
            </div>
            <br />
            <hr /> <br />
            <br />
            {/* terms condition */}
            <div className="form-check ">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                id="flexCheckDefault"
                {...register("acceptTerms", { required: true })}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I have read and accept the{" "}
                <a
                  href="https://cap-tech.in/terms-conditions"
                  target="blank"
                  style={{ color: "unset" }}
                >
                  terms and conditions
                </a>{" "}
                as well as{" "}
                <a
                  href="https://cap-tech.in/privacy-policy"
                  target="blank"
                  style={{ color: "unset" }}
                >
                  {" "}
                  privacy policy{" "}
                </a>
                before submitting
              </label>
              <br />
              {errors.acceptTerms && (
                <span style={{ color: "red" }}>Accept t&c.</span>
              )}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                id="flexCheckDefault"
                {...register("acceptMsg", { required: true })}
              />

              <label className="form-check-label" htmlFor="flexCheckDefault">
                I agree to receive messages on WhatsApp
              </label>
              <br />
              {errors.acceptMsg && (
                <span style={{ color: "red" }}>Accept t&c.</span>
              )}
            </div>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <button
                type="submit"
                // onClick={submitForm}
                className="btn button1"
                style={{
                  background: "#f47d35",
                  color: "#fff",
                  padding: "10px 30px",
                }}
              >
                Submit
              </button>
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
      <ToastContainer position="top-center" />
    </>
  );
}

export default Real2;
