import react,{ Component ,useState, useEffect } from "react";

import section2 from './assets/client/arihant.png'; 
import slider1 from './assets/client/capacite.png'
import slider2 from './assets/client/jkkumar.png'
import slider3 from './assets/client/oberoi.png'
import slider4 from './assets/client/pepsico.png'
import slider5 from './assets/client/tata.png'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";





export default class SimpleSlider extends Component {

    render() {
      
      let slideCount=3;

      
    
      const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 4,
        autoplay:true,
        arrows:false
      };

      if(window.innerWidth<=768)
      {
      
        slideCount=1
      }else{
        slideCount=5
      
      }

     
      return (
        <>
        
    <div className="container-fluid" style={{width:"95%"}}>
        <div className="row">
           <div className="col-md-12" style={{textAlign:"center", margin:"10px", height:"147px"}}>
          <Slider  slidesToShow={slideCount} {...settings}>
          <img src={slider1} alt="img1" className="slider " style={{width:"auto"}} height="100px" />
        <img src={slider2} alt="img1" className="slider " height="100px"  style={{width:"auto"}}/>
        <img src={slider3} alt="img1" className="slider " height="100px"  style={{width:"auto"}}/>
        <img src={slider4} alt="img1" className="slider " height="100px"  style={{width:"auto"}}/>
        <img src={slider5} alt="img1" className="slider " height="100px"  style={{width:"auto"}}/>
        <img src={slider1} alt="img1" className="slider " style={{width:"auto"}} height="100px" />
        <img src={slider2} alt="img1" className="slider " height="100px"  style={{width:"auto"}}/>
        <img src={slider3} alt="img1" className="slider " height="100px"  style={{width:"auto"}}/>
       
         </Slider>

          </div>
          </div>
          </div>
        </>
      );
    }
  }
