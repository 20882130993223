import React from "react";

const Spinner = ({ size = "50px", color = "#007bff" }) => {
  return (
    <div className="spinner-container">
      <div className="spinner" style={{ width: size, height: size, borderColor: `${color} transparent ${color} transparent` }}></div>
    </div>
  );
};

export default Spinner;
