import React,{Component} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import section2 from '../assets/section-2.png';
import t2 from '../assets/section3/m1.png';
import t3 from '../assets/section3/m2.png';
import t4 from '../assets/section3/m3.png';
import t5 from '../assets/section3/m4.png';
import icon from '../assets/a.png';
import './MediaCoverage.css'
export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
     
      slidesToScroll: 1,
      autoplay:true,
      arrows:false
    };

    let  slideCount=3;
    if(window.innerWidth<=768)
    {

      slideCount=1
    }else{
      slideCount=3
    
    }
    return (
      <>
      
      <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h1 className="sec_6_header">Media Coverage</h1>
                <p className="sec_6_content">eFORCE is an “Easy to you” Platform, created to facilitate “connecting General Contractors and developers with relevant service providers”, “transparency of payments cycles” as well as Intelligent Reporting. It is available as a Web and
                    mobile app. </p>
                    {/* <a href="#"><p className="sec_6_btn">View More</p></a> */}
            </div>

          
            <Slider  slidesToShow={slideCount} {...settings}>
            <div className="col-md-4">
                <div className="card">
                        <h1 className="card_media_heading"> &#8220; </h1>
                        <p className="card_media_content">Construction tech platform "Captech technologies Pvt. Ltd." </p>
                </div>
                        <img src={t2} className="userimg" alt="user" width="100%"/>
                        {/* <p className="userName">Mr. Atul Araskar</p> */}
            </div>
            <div className="col-md-4">
                <div className="card">
                        <h1 className="card_media_heading">&#8220;</h1>
                        <p className="card_media_content">Captech Technologies Launches India's First Construction Labour Marketplace eFORCE </p>
                </div>
                        <img src={t3} className="userimg" alt="user" width="100%"/>
                        {/* <p className="userName">Mr. Prashant Ghande</p> */}
            </div>
            <div className="col-md-4">
                <div className="card">
                        <h1 className="card_media_heading">&#8220; </h1>
                        <p className="card_media_content">Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</p>
                </div>
                        <img src={t4} className="userimg" alt="user" width="100%"/>
           </div>
            <div className="col-md-4">
                <div className="card">
                        <h1 className="card_media_heading">&#8220; </h1>
                        <p className="card_media_content">Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</p>
                </div>
                        <img src={t5} className="userimg" alt="user" width="100%"/>
           </div>



         
           </Slider>
           

          

        </div>
    </div>

      
      </>
    );
  }
}
