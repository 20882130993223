import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import section2 from '../assets/section-2.png';
import './Testimonial.css'
import t_1 from '../assets/section3/t-1.png';
import t_2 from '../assets/section3/t-2.png';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false
    };
    return (
      <>

        <div className="container-fluid section6" >
          <div className="container">
          <div className="row">
            {/* content */}
              <h1 className="user_headign mt-5 pt-5 text-center">Testimonials</h1><br />
              <Slider {...settings}>
                {/* 1 tushar mirchandani  */}
                <div className="user_content mb-5">
                <div className="user_img mt-0 mb-0">
                  <img src="/assets/tushar.png" className="member-div mt-4" alt="Membership" style={{width:'190px'}} />
                  <p className="headingName mt-3">Mr.Tushar Chavan </p>
                  <span className="subname"> Mirchandani group</span>
                </div>
                  <p className="text-center mt-4" style={{lineHeight:'24px'}}>Excellent app which build direct bridge between developer and contractor. Also the entire process is very smooth and faster. Along with eFORCE representative/ customer care team takes continuous followup from both sides so that no work hindrances occur at any stage. Also in this app have there are lots of options to choose specific contractors with past work references.
                    Best wishes from ourside for future journey.
                  </p>

                </div>

                {/* MGM aurangabad */}
                <div className="user_content mb-5">
                <div className="user_img mt-0 mb-0">
                  <img src="/assets/test-1.png" className="member-div mt-4" alt="Membership" style={{width:'190px'}} />
                  <p className="headingName mt-3">Mr.Rahul Tripati</p>
                  <span className="subname">MGM Aurangabad</span>
                </div>
                  <p className="text-center mt-4" style={{lineHeight:'24px'}}>I have deployed 10 labours at MGM Aurangabad for painting work, I have received the lead of this work from eFORCE application. Even during lockdown, I have received work which is the best thing about the app. It is very useful as the leads and projects provided by eFORCE application have been very helpful to find different works for me which was not possible without this app. I am looking forward to working at PAN India level for different activities with the help of this app.</p>
                </div>
              </Slider>


            
            {/* img */}
            {/* <div className="col-md-4">
              <Slider {...settings}>
                <div className="user_img">
                  <img src="/assets/tushar.png" className="member-div" alt="Membership" />
                  <p className="headingName">Mr.Tushar Chavan </p>
                  <span className="subname"> Mirchandani group</span>
                </div>
                <div className="user_img">
                  <img src="/assets/test-1.png" className="member-div" alt="Membership" />
                  <p className="headingName">Mr.Rahul Tripati</p>
                  <span className="subname">MGM Aurangabad</span>
                </div>
              </Slider>
            </div> */}





          </div>
          </div>
        </div>

      </>
    );
  }
}


// <Slider {...settings}>
//         <img src={section2} alt="eror" width="100%"/>
//          <img src={section2} alt="eror" width="100%"/>
//          <img src={section2} alt="eror" width="100%"/>
//         </Slider>


