import React from "react";
import '../styles/Terms.css';


function Privacy(){
    return(
        
        <>
               {/* sub heading */}
               <div className="container-fluid mediapageSection">
                <div className="row">
                    <div className="col-md-12">
                            <h1 className="Contactheading"> Privacy Policy</h1>
                   </div>
                </div>
            </div>



            {/* Data */}

          
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <br></br>  <br></br>  <br></br>
                <h6>INTRODUCTION</h6><br></br>
                    <ul>
                        <li>Captech Technologies Private Limited (Captech) is committed to safeguard the privacy of the users of the website https://cap-tech.in and application known as eFORCE (hereinafter collectively referred to as the “Site"). CAPTECH’s Privacy Policy (“Policy”), which forms part of the Site Terms and Conditions, explains the data processing practices of CAPTECH in so far as it relates to the CAPTECH Site..</li>    
                        <br></br>
                        <li>By using the Site, you are consenting to this Policy and the collection, use and disclosure of your personal information by CAPTECH as outlined in this Policy. If this Policy is not acceptable to you, please do not submit any of your personal information and/or use the Site.</li>
                        <br></br>
                        <li>If you have any request concerning your personal information, or should you wish to make any amendment to your personal information or have any queries with regard to the Policy or any of the above please contact us by sending an email to helpdesk@cap-tech.in.</li>
                        <br></br>
                        <li>If we make any changes to this Policy, we will post them on this page and as such it is your responsibility to periodically review this Policy in order to stay up to date on any modifications and/or amendments made by CAPTECH.</li>
                        <br></br>
                    </ul>    
            </div>

            <div className="col-md-12">
                <br></br><br></br><br></br>
                <h6>PERSONAL INFORMATION COLLECTED
</h6><br></br>
                <ul>
                 <li>CAPTECH may collect and store a variety of personal information about you when you either directly submit such information to CAPTECH either electronically or otherwise, or through the use of the Site, CAPTECH products and/or services. The personal information that we may collect, process and store includes but is not limited to:</li>
                    <br></br>
                    <ul>

                        <li>Name and Surname;</li><br></br>
                        <li>Phone numbers;</li><br></br>
                        <li>Email address;</li><br></br>
                        <li>Company name;</li><br></br>
                        <li>Physical address;</li><br></br>
                        <li>Which services you have subscribed and details around the usages of CAPTECH products and/or services.</li><br></br>
                        <li>Credit/Debit Card Details</li><br></br>
                            <ul>
                                <li>First 4 and last 4 digits;</li><br></br>
                                <li>Card Name;</li><br></br>
                                <li>Expiry date;</li><br></br>
                                <li>Token provided by the payment gateway.</li><br></br>

                            </ul>
                        <li>Any documentation relating to you or your company.</li><br></br>
                        <li>By using this Site you consent to your personal information being shared with 3rd party service providers and you authorise CAPTECH to accept on your behalf the terms and conditions set by such 3rd parties to enable them to provide the services in accordance with their agreements.</li>
                        <br></br>
                        <li>CAPTECH and its designated 3rd party service providers may from time to time amend their terms and conditions without prior notice and it is incumbent on you to familiarise yourself with such amendments on a regular basis.</li>
                        <br></br>
                        <li>Please note that we do not knowingly collect Personal Information from users under the age of 18. By using our Site, you hereby expressly confirm that you are at least 18 years of age and legally eligible to enter into a binding contract and share Personal Information</li>
                        <br></br>

                     </ul>

                        <li>We may also collect your IP address and other non-personal information about you whenever you interact with our Site. Non-personal information may include but is not limited to the browser name, the type of computer and technical information about the means of connection to our Site, such as the operating system and the internet service providers utilised and other similar information.</li>
                        <br></br>
                        <li>If you have any request concerning your personal information, or should you wish to make any amendment to your personal information or have any queries with regard to the Policy or any of the above please contact us by sending an email to helpdesk@cap-tech.in.</li>
                        <br></br>
                        <li>If we make any changes to this Policy, we will post them on this page and as such it is your responsibility to periodically review this Policy in order to stay up to date on any modifications and/or amendments made by CAPTECH.
</li><br></br>
                </ul>
            </div>


            <div className="col-md-12">
                <br></br><br></br><br></br>
                <h6>RESTRICTIONS ON USE</h6><br></br>
                <ul>
                   <li> You must not, other than as provided for in these T’s & C’s:</li><br></br>
                    <li>Remove any copyright, trademark or other intellectual property right notice contained in the Site;</li>
                           <br></br>
                  <li>Use the material from the Site in any manner or for any purpose which is unlawful or in any manner which violates any right of CAPTECH.</li>
                    <br></br>
                    <li>To the extent permitted by law, you hereby indemnify CAPTECH against any and all claims arising from the use by 3rd parties of any material from the Site that they have accessed as a result of your reproduction, redistribution, alteration or transmission of that material in contravention of these T’s & C’s.</li>
                    <br></br>
                </ul>
            </div>

            <div className="col-md-12">
                <br></br><br></br><br></br>
                <h6>REGISTRATION AND CANCELLATION
</h6><br></br>
                <ul>
                   <li>This Site is available free of charge, in order to access the Site you will be required to complete an account registration process and verify your contact details before you are allowed access to the Site.</li>
                    <br></br>
                    <li>When you register for access to the Site, you may be asked to provide certain personal details (“Personal Information”). CAPTECH will only use the Personal Information in accordance with the Privacy Policy.</li>
                    <br></br>
                    <li>CAPTECH uses cookies and similar technologies in our Websites and Services that help us collect other Information about you across other websites and online services.</li>
                    <br></br>
                    <li>CAPTECH reserves the right to terminate your registration to the Site and to restrict your access to the Site without notice should you at any time be in breach of these user T’s & C’s, any agreements concluded with CAPTECH and the Privacy Policy.</li>
                    <br></br>
                    <li>You may cancel your registration with the Site at any time by sending an email to helpdesk@cap-tech.in</li>
                    <br></br>
                    <li>Personal information will be deleted from CAPTECH systems within ninety (90) days of you cancelling your account with CAPTECH.</li>
                    <br></br>
                  </ul>
            </div>



        </div>
    </div>
        </>
    )
}


export default Privacy;