import React, { lazy } from 'react';
import logo from './logo.svg';
import './App.css';

// const Header = lazy(() => import('./Header'));
// const MobileNav = lazy(() => import('./navbar/MobileNav'));

import MobileNav from './navbar/MobileNav';
import Header from './Header';

import HomePage from './HomePage';
import Footer from './Footer'; 
import MediaCoverage from './components/MediaCoverage';

import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Career from './pages/Career';
import GCForm from './pages/GCForm';
import LCForm from './pages/LCForm';
import Media from './pages/Media';
import Blogs from './pages/Blogs';
import  RealEstate from './GC/RealEstate/RealEstate';
import  Real2 from './GC/RealEstate/Real2';
import PageNotFound from './components/PageNotFound';
import Faq from './components/Faq';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

import {Routes, Route} from 'react-router-dom'



let mobielNavChehck=true;

if(window.innerWidth<=768)
{

  mobielNavChehck=false;
}else{
  mobielNavChehck=true;

}
function App() {
  return (
   
  <>

  {mobielNavChehck? <Header/>:<MobileNav/>}

      <Routes basename={process.env.PUBLIC_URL}>
        <Route path="/" exact  element={<HomePage/>}/>
        <Route path="/about"  element={<AboutUs/>}/>
        <Route path="/media"  element={<Media/>}/>
        <Route path="/contact"  element={<ContactUs/>}/>
        <Route path="/career"  element={<Career/>}/>
        <Route path="/gc"  element={<GCForm/>}/>
        <Route path="/lc/src/:data"  element={<LCForm/>}/>
        <Route path="/lc/src"  element={<LCForm/>}/>
        <Route path="/lc"  element={<LCForm/>}/>
        <Route path="/blog"  element={<Blogs/>}/>
        <Route path="/real"  element={<RealEstate/>}/>
        <Route path="/real2"  element={<Real2/>}/>
        <Route path="/Faq"  element={<Faq/>}/>
        <Route path="/terms"  element={<Terms/>}/>
        <Route path="/privacy"  element={<Privacy/>}/>
        <Route path="*"  element={<PageNotFound/>}/>
        
        </Routes>  
      <Footer/>
   
    
    </>
  );
}

export default App;
