import React from "react";

import '../styles/ContactUs.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


function ContactUs() {
    return (

        <>
            {/* sub heading */}
            <div className="container-fluid contactpageSection">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Contactheading"> Contact us</h1>
                    </div>
                </div>
            </div>


            {/* contect us form */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <br /><br />
                        <div className="container form-section ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4 rounded-2">
                                            <div className="bg-w py-2 rounded-3">
                                            <div className="bg-contact">
                                                <ul className="border-b pb-2 px-0">
                                                    <li className="pb-1 text-white">Support Number</li>
                                                    <li className="pb-2 call-text mr-2 call-text"> 1800 266 7037</li>
                                                    
                                                </ul>
                                            </div>

                                            <div className="bg-contact py-1">
                                                <ul className="border-b pb-2 px-0">
                                                    <li className="pb-1 text-white">Support Email Address</li>
                                                    <li className="pb-2 mr-2 call-text" > helpdesk@cap-tech.in</li>
                                                    
                                                </ul>
                                            </div>


                                            <div className="bg-contact">
                                                <ul className="border-b pb-2 px-0">
                                                    <li className="pb-1 text-white">Whatsapp Support</li>
                                                    <li className="pb-2 mr-2 call-text" >+917738128563 </li>
                                                    
                                                </ul>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 p-0">
                                        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15085.375043977438!2d72.88705314546638!3d19.04861769603031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6068e37d755%3A0x87dc16d51b98a946!2sCapacite%20Structures%20Limited!5e0!3m2!1sen!2sin!4v1738590104836!5m2!1sen!2sin"
        width="100%"
        height="290"
        style={{ border: 0 , borderRadius:'5px' }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
                                        </div>

                                    </div>
                                </div>
                            </div>
                           
                            <div className="row justify-content-center">

                            </div>
                            <br /><br />
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>
        </>
    )
}


export default ContactUs;