import React , { Component } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import section2 from '../assets/media.png'; 
import './Membership.css'

export default class SimpleSlider extends Component {
  render() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
     
        slidesToScroll: 1,
        autoplay:true,
        centerMode: true,
        arrows:false
    };

    let  slideCount=3
    if(window.innerWidth<=768)
    {
    
      slideCount=1
    }else{
      slideCount=3
    
    }
    return (
      <>
      
      <div className="container-fluid memborship" >
        <div className="row">
            <div className="col-md-12">

            

            {/* <Slider  slidesToShow={slideCount} {...settings}>

              <img src={section2} alt="eror" width="100%" />
              <img src={section2} alt="eror" width="100%"/>
              <img src={section2} alt="eror" width="100%" />
              <img src={section2} alt="eror" width="100%" />
              <img src={section2} alt="eror" width="100%" />
             
        </Slider> */}
            </div>
        </div>
      </div>
      
      </>
    );
  }
}


