import React, { useState ,useEffect} from 'react';
import './common.css'

import Button from '@mui/material/Button';
import { Accordion } from '@mui/material';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material';
import { AccessAlarm, ThreeDRotation,Add,Remove } from '@mui/icons-material';

const Faq = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
      console.log("effrece run");
    }, [expanded])
    return (
        <>

            <div className='container-fluid faq_section'>

                <div className='container faq_inner_Content  mt-5'>
                    <div className="row">
                        <div className='col-md-12'>
                            <h3 className='faq_heading'>Frequentyly Asked Questions About eFORCE</h3>
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className='faq_title'>1.What is eFORCE ?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        eFORCE is a Construction Tech Platform which bridges the gap between the General Contractors/Developers and Sub-Contractors/Labor-Contractors to fulfill each other requirements.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 2 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>2.Why eFORCE?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        EFORCE platform can help connect General Contractors/Developers with relevant labor Contractors & specialized vendors. Through this initiative we believe we can help the Indian Construction industry bounce back faster while providing relevant and continuous work to India’s skilled and unskilled migrant labour.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 3 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>3.Which Stakeholders can register on eFORCE?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        General Contractor (Builders & Developers) & Labor / Sub Contractors.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 4 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>4.How to register on eFORCE as a General Contractor (Builders & Developers)?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        You need to call on our toll free number 18002667037 our representative will assist further.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 5 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'> 5.How to register on eFORCE as a Sub Contractor?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        There are 2 two ways to register either through simply visiting our website <a href='http://www.cap-tech.in/sub-contractor/src/0'>www.cap-tech.in </a> or You need to call on our toll free number 18002667037 our representative will assist further.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 6 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>6.Can a labor register on eFORCE?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        No, only contractors can register.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 7 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>7.Can I register multiple companies on eFORCE?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        Yes, with unique GST, PAN & Mobile Number.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 8 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>8.How to login on eFORCE?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        You can simply login with your registered mobile number with an OTP.


                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 9 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>9.How to add new supply on eFORCE?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        Once login into the eFORCE application {'>>>'} Click on Supply {'>>>'}Click on (+) {'>>>'}Add Activity/Sub Activity/Labor counts {'>>>'}Save
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* 10 */}
                            <Accordion style={{backgroundColor:'#171717', 'boxShadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)',
'-webkit-box-shadow': '-1px 19px 24px 0px rgba(43,43,43,0.75)',"marginBottom":'3px',
'-moz-box-shadow':' -1px 19px 24px 0px rgba(43,43,43,0.75)'}} expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                <AccordionSummary
                                    expandIcon={expanded?<Remove/>:<Add/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className='faq_title'>10.How to add new requisition on eFORCE?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='faq_content'>
                                        Once login into the eFORCE application{'>>>'}lick on Requisition{'>>>'}lick on (+){'>>>'}elect Name{'>>>'}elect Project Type{'>>>'}dd Activity/Sub Activity/Labor counts/Volume/Work front{'>>>'}Save
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq
