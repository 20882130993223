import React,{Component} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import section2 from '../assets/section-2.png';
import t2 from '../assets/section3/m1.png';
import t3 from '../assets/section3/m2.png';
import t4 from '../assets/section3/m3.png';
import t5 from '../assets/section3/m4.png';
import icon from '../assets/a.png';
import './MediaCoverage.css';

export default class HowToSimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
     
      slidesToScroll: 1,
      autoplay:true,
      arrows:false
    };

    let  slideCount=3;
    if(window.innerWidth<=768)
    {

      slideCount=1
    }else{
      slideCount=3
    
    }
    return (
      <>
      
      <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h1 className="sec_6_header">How to use eFORCE</h1>
              
            </div>

          
            <Slider  slidesToShow={slideCount} {...settings}>
            <div className="col-md-4">
                <div className="card">
                <iframe width="auto" height="315" src="https://www.youtube.com/embed/054IhANcoN0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                      
            </div>
            <div className="col-md-4">
                <div className="card">
                <iframe width="auto" height="315" src="https://www.youtube.com/watch?v=mNydOtpUNH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  </div>
                       
            </div>
            <div className="col-md-4">
                <div className="card">
                <iframe width="auto" height="315" src="https://www.youtube.com/embed/az5z4xldx5s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  </div>
                     
           </div>
            <div className="col-md-4">
                <div className="card">
                <iframe width="auto" height="315" src="https://www.youtube.com/embed/mNydOtpUNH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   </div>
                       
           </div>
            <div className="col-md-4">
                <div className="card">
                <iframe width="auto" height="315" src="https://www.youtube.com/embed/1khEN-JG3K4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
           </div>



         
           </Slider>
           

          

        </div>
    </div>

      
      </>
    );
  }
}
