import React ,{useEffect} from "react";

import addvertise from '../assets/referral/popup.png';
import real from "../assets/real.png";
import road from "../assets/road.png";

// install fa fa icons
import { FaHandPointRight  } from 'react-icons/fa';
// import { BiRupee } from "@react-icons/all-files/fa/BiRupee";
import { FaBeer } from "@react-icons/all-files/fa/FaBeer";

import '../styles/GCForm.css';
import HowToSimpleSlider from "../components/HowTo";

function GCForm() {


    useEffect(() => {
        window. scrollTo(0,0);
    }, []);
    return (

        <>
            {/* sub heading */}
            <div className="container-fluid lcpageSection">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Contactheading"> Registration for General Contractor</h1>
                    </div>
                </div>
            </div>



            <div className="container-fluid" style={{marginTop:'4%'}}>


                <div className="row">

                    {/* <div className="col-md-5">
                        <img src={addvertise} height="100%" className="img-fluid banner-img" width="100%" />
                    </div> */}
                    <div className="col-md-3">

                        <div className="reg-button">

                            <div className="reg-form">
                                <h1 className="count-name">Register as a<br/> General Contractor / Developer ?</h1>


                                <div className="gc_section_1_div">
                                    <div className="strip"></div>

                                    {/* <a target="_blank" href="http://referral.cap-tech.in/" className="url-gc"> */}
                                        <div className="card-counter primary">
                                            <img src={real} />
                                            <h6 className="cporng">Real Estate</h6>
                                        </div>
                                    {/* </a> */}
                                </div>
                                <div className="gc_section_1_div"  >
                                    <div className="strip"></div>
                                    {/* <a target="_blank" href="http://referral.cap-tech.in/roads" className="url-gc "> */}
                                        <div className="card-counter primary">
                                            <img src={road} />
                                            <h6 className="cporng">Roads & Highways</h6>
                                        </div>
                                    {/* </a> */}
                                </div>
                            </div>

                        </div>




                    </div>
                    <div className="col-md-9">
                        <div className="founder">
                            <div className="ceo-vdo">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/esblK5Yyyig?autoplay=0&showinfo=0&controls=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                                <h1 className="ashu" style={{fontSize: '18px'}} >Asutosh Katyal</h1>
                                <h4 className="ashu" style={{fontSize: '13px', color: 'white'}}>Founder & CEO</h4>
                            </div>
                        </div>
                    </div>




                  
                

                </div>

                <div className="row">
                <HowToSimpleSlider /><br></br><br></br>
                </div>



            </div>






            {/* <div className="container-fluid">
                <div className="col-md-12 text-center " style={{marginTop: '44px'}} >

                    <h4 className="slider-title" ><span style={{color: '#f47d35'}}>eFORCE</span> Referral Program </h4><br/>

                        <ul>
                            <li> <FaHandPointRight className="icon_freehand"/>You have to refer the &nbsp;
                                <a style={{textDecoration: 'none', color: '#595959'}} target="_blank" href="https://play.google.com/store/apps/details?id=com.eforce.eforce_pi"><span style={{fontWeight: '600',color: '#f47d35'}}>eFORCE</span> </a> &nbsp; App to your peers and friends
                                in the construction industry (especially project in charges / project manager / project heads).</li>
                            <li><FaHandPointRight className="icon_freehand"/>These Project In Charges / Managers need to fill online form available on above.</li>
                            <li> <FaHandPointRight className="icon_freehand"/>Kindly note that the referral code is your EMPLOYEE CODE (for Capacit'e employees) and your 10 digit registered mobile number for our partners registered on the eFORCE platform.</li>
                            <li> <FaHandPointRight className="icon_freehand"/>Upon their successful project registration & subscribing to annual membership plan; You will get an assured referral bonus of <strong> <i style={{color: 'black',fontSize:'14px', paddingRight:'3px',paddingLeft: '3px'}} className="fa fa-inr" aria-hidden="true"></i>2,500 per project registration.</strong></li>

                        </ul>


                        <p className="text-left"> <strong> For Example : </strong> </p>
                        <div className="table table-responsive ">
                            <table className=" table-striped  table-bordered" style={{width: '100%'}}>
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'left'}} scope="col">Number of Successful Project registration</th>
                                        <th style={{textAlign: 'left'}} scope="col">Nature of Projects </th>
                                        <th style={{textAlign: 'left'}} scope="col">Company Name (indicative) </th>
                                        <th style={{textAlign: 'left'}} scope="col">Annual Membership per project</th>
                                        <th style={{textAlign: 'center'}} scope="col">Total Referral Bonus</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>1 Project</td>
                                        <td>Residential Building</td>
                                        <td>UTF Projects</td>
                                        <td>&#8377;  24,000 + GST / project</td>
                                        <td> &#8377; 2,500</td>
                                    </tr>
                                    <tr>
                                        <td>2 Project</td>
                                        <td>Commercial  Building</td>
                                        <td>SQW Projects</td>
                                        <td> &#8377;  24,000 + GST / project</td>
                                        <td> &#8377; 5,000</td>
                                    </tr>
                                    <tr>
                                        <td>5 Project</td>
                                        <td>Institutional Building</td>
                                        <td>JMK Projects</td>
                                        <td>&#8377;  24,000 + GST / project</td>
                                        <td> &#8377; 12,500</td>
                                    </tr>
                                    <tr>
                                        <td>10 Project</td>
                                        <td>Roads and Highways </td>
                                        <td>Kumar Buildcon </td>
                                        <td>&#8377;  24,000 + GST / project</td>
                                        <td> &#8377; 25,500</td>
                                    </tr>

                            </tbody>
                        </table>
                </div>

            </div>
            </div> */}




        </>
    )
}


export default GCForm;