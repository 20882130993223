import React, { useState } from "react";

const paymentInfo = [
  {
    desc: "eFORCE Subscription for 1 Project",
    price: "28,320.00",
    link: "https://rzp.io/i/gxkywkB"
  },
  {
    desc: "eFORCE Subscription for 2 Project",
    price: "56,640.00",
    link: "https://rzp.io/i/kBThpgoQvs"
  },
  {
    desc: "eFORCE Subscription for 3 Project",
    price: "84,960.00",
    link: "https://rzp.io/i/PHGjCNdoz"
  }
];

export default function Payment() {
  const [payInd, setPayInd] = useState(0);

  return (
    <section>
      {/* <div className="panel-heading payment-head">
        <h3>No of Project Registered</h3>
        <h3>Subscription</h3>
        <h3>Amount</h3>
        <div />
      </div> */}
      <div className="payment-body">
        <label>eFORCE Subscription for 1 Project (Annually)</label>
        {/* <div>{payInd + 1}</div> */}
        {/* <div>
          <label>No of Project Registered</label>
          <select
            className="form-select payment-select"
            onChange={(e) => setPayInd(+e.target.value)}
          >
            {paymentInfo.map((item, i) => (
              <option key={i} value={i}>
                {item.desc}
              </option>
            ))}
          </select>
          <br />
        </div> */}
        <div>
          <label>Amount : </label>
          <b> ₹ 28,320.00</b>
          <div>
            {/* <b>₹ {paymentInfo[payInd].price}</b> */}
            {/* <b>₹ 28,320.00</b> */}
            <br></br>
          </div>
        </div>
        <div>
          {/* <p className="panel-heading payment-head">test </p> */}
          <a
            style={{ color: "#fff" }}
            className="btn payment-btn"
            target="_blank"
            rel="noreferrer"
            // href={paymentInfo[payInd].link}
            href="https://rzp.io/i/gxkywkB"
          >
            Pay Now
          </a>
        </div>
      </div>
    </section>
  );
}
