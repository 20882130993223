import react from "react";
import './Footer.css';
import logo from './assets/logoo.png';

import { Route, Switch, NavLink } from "react-router-dom";
import { FaFacebookF } from 'react-icons/fa';


function Footer() {
    return (
        <>
            <section id="footer">
                <div className="container">
                    <div className="row text-center text-xs-center text-sm-left text-md-left">
                        <div className="col-xs-12 col-sm-4 col-md-4">
                            <a className="navbar-brand" href="#">
                                <img src={logo} className="img-responsive" style={{ width: '250px' }} />
                            </a><br /><br />
                            <p className="footer_content">Captech technologies pride itself by being deeply associated with the- Capacit’e group The group’s flagship company Capacit’e Infraprojects Limited is a well-known and name in construction industry with several prestigious SUPER HIGH-RISE
                                STRUCTURES adorning their portfolio</p><br />

                            <ul className="list-unstyled list-inline social ">
                                {/* <li className="list-inline-item"><a href="#"><i className="fa fa-instagram"></i></a></li>
                    <li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li> */}
                                <li className="list-inline-item"><a target="_blank" href="https://m.facebook.com/Captech-Technologies-Pvt-Ltd-109740037836717/"> <FaFacebookF /></a></li>
                            </ul>


                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2">
                            <p className="footer_heading">Company</p>
                            <ul className="list-unstyled quick-links">
                                <li><NavLink className="active" to="/"> Home</NavLink></li>
                                <li><NavLink to="/about"> About us</NavLink></li>
                                <li><NavLink to="/gc"> General Contractor</NavLink></li>
                                <li><NavLink to="/lc"> Sub Contractor Registration</NavLink></li>
                                <li><NavLink to="/media"> Media</NavLink></li>
                                {/* <li><NavLink to="/blog"> Blogs</NavLink></li> */}
                            </ul>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 ">
                            <p className="footer_heading">Support</p>
                            <ul className="list-unstyled quick-links">
                                <li><a href="#">FAQs</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms & conditions</a></li>
                            </ul>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 ">
                            <p className="footer_heading">Contact Us</p>
                            <ul className="list-unstyled2 quick-links ">
                                <p className="mb-0">Email</p>
                                <li className="mb-3"><a href="mailto:helpdesk@cap-tech.in">helpdesk@cap-tech.in</a></li>
                                <p className="mb-0">Whatsapp Support</p>
                                <li className="mb-3"><a href="https://api.whatsapp.com/send?phone=7738128563" class="mr-2 call-text">+917738128563 </a></li>
                                <h6>Toll free no. <a href="tel:18002667037" style={{ color: 'white' }}>1800 266 7037</a></h6>
                                <li>Address</li>
                                <p>605-607, SHRIKANT CHAMBERS, PHASE-I, 6TH ADJACENT TO R. K. STUDIOS, SION-TROMBAY MUMBAI Mumbai City MH 400071 IN</p>
                            </ul>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5 " style={{ height: "1px", backgroundColor: "#8695A4" }} >

                        </div>
                        <hr />
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">

                            <p className="h6"><a className="text-green " href="#" target="_blank">Captech Technologies Pvt. Ltd.</a>© all right reversed.</p>
                        </div>

                    </div>
                </div>
            </section>



        </>
    )
}

export default Footer;