import React from "react";
import './MobileNav.css'

import logo from '../assets/logomob.png';

import { Route, Switch, NavLink } from "react-router-dom";


function MobileNav() {

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-red navbar-dark">
                <div className="wrapper"> </div>
                <div className="container-fluid all-show">  <NavLink className="navbar-brand" to="/"><img src="/assets/logo.png" alt="logo" width="80%"/> </NavLink>  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                            <li className="nav-item"> <NavLink className="nav-link mobSize active" to="/">Home</NavLink> </li>
                            {/* <li className="nav-item" data-toggle="collapse"> <NavLink className="nav-link  mobSize" aria-current="page" to="/about">About us</NavLink> </li> */}
                            <li className="nav-item"> <NavLink className="nav-link mobSize" to="/about">About us</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link mobSize" to="/gc">General Contractor</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link mobSize" to="/lc/src/0">Sub Contractor Registration</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link mobSize" to="/media">Media</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link mobSize" to="/career">Career</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link mobSize" to="/Contact">Contact Us</NavLink> </li>
                        </ul>

                    </div>
                </div>
            </nav>


        </>
    )
}

export default MobileNav;