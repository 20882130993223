import React, { useEffect } from "react";

import '../styles/Media.css';

import logo2 from '../assets/medialogo.png'
import media_view from '../assets/media.png'

function Media() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (

        <>
            {/* sub heading */}
            <div className="container-fluid mediapageSection">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Contactheading"> Media Coverage</h1>
                    </div>
                </div>
            </div>


            {/* contect us form */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <br /><br />
                        <div className="container form-section ">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 17, 2020 </p>
                                    <div className="row">
                                        <div className="col-md-12" style={{ textAlign: "center", marginBottom: "1%" }}>
                                            {/* <img src={media_view} alt="media view" width="100%"/> */}
                                            <video width="50%"   controls>
        <source src="/assets/ETNow-Interview.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
                                            {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/okU5azTOaTQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                        </div>
                                    </div>

                                    <div className="row media_content ">
                                        <div className="col-md-11">
                                            <h5 className="mb-3">Construction tech platform "Captech technologies Pvt. Ltd."</h5>
                                            <a target="_blank" href="about" className="">Read More..</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-ET_Now.png" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                    {/* <p className="media_read">Read More</p> */}
                                </div>


                            </div>
                        </div>
                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 15, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://timesofindia.indiatimes.com/captech-technologies-launches-indias-first-construction-labour-marketplace-eforce/articleshow/76257510.cms" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-india-today.png" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE - Captech is part of construction major Capacit'e Infraprojects Group</h5>
                                            <a target="_blank" href="https://accommodationtimes.com/pr-newswire/?rkey=20200608EN27835&filter=18279" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-accommodationtimes.png" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies, a Capacit'e Infraprojects Group associate company has recently 
                                                launched India's first multi-lingual construction tech platform 'eforce' to bridge in the
                                                 gap between general contractors/project developers, migrant labour contractors & specialized 
                                                 vendors.</h5>
                                            <a target="_blank" href="https://www.uniindia.com/captech-technologies-launches-indias-first-construction-labour-marketplace-eforce/prnewswire/news/2029729.html" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-unitednews.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://ians.in/english-wire/all/" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-ians.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.dsij.in/login?returnurl=%2fnewswiredetails%2ffilename%2f202006080310pr_news_euro_nd__en27835" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-dalalstreet.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.businesstoday.in/prnewswire/?rkey=20200608EN27835&filter=2418" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-Business-Today.png" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.tmcnet.com/usubmit/-captech-technologies-launches-indias-first-construction-labour-marketplace-/2020/06/08/9163688.htm" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-tmcnet.png" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.tmcnet.com/usubmit/-captech-technologies-launches-indias-first-construction-labour-marketplace-/2020/06/08/9163688.htm" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-PTI.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://old.ptinews.com/pressrelease/41145_press-subCaptech-Technologies-Launches-India-s-First-Construction-Labour-Marketplace-eFORCE" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-Yahoo_Finance.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://in.search.yahoo.com/?fr2=inr" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-devdiscourse.png" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.devdiscourse.com/article/business/1083374-captech-technologies-launches-indias-first-construction-labour-marketplace-eforce" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-THE_WEEK.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-9">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.theweek.in/wire-updates/business/2020/06/08/pwr4-captech-technologies.html" className="">Read More</a>
                                        </div>
                                        <div className="col-md-3 media_logo">
                                            {/* <img src={logo2} alt="error" /> */}
                                            <h5>Daily Business News</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://dailybbusinessnews.blogspot.com/2020/06/captech-technologies-launches-indias.html" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-gpn.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://globalprimenews.com/2020/06/06/captech-technologies-launches-indias-first-construction-labour-marketplace-eforce/" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-CW.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.constructionworld.in/page-not-found" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-varindia.png" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 8, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://varindia.com/news/captech-technologies-launches-indias-first-construction-labour-marketplace-eforce#:~:text=Captech%20Technologies%2C%20a%20Capacit'e,migrant%20labour%20contractors%20%26%20specialized%20vendors" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-CNBC.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 5, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies launches construction labour marketplace ‘eFORCE’</h5>
                                            <a target="_blank" href="https://www.cnbctv18.com/real-estate/captech-technologies-launches-construction-labour-marketplace-eforce-6080571.htm" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-iifl.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container form-section pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>June 5, 2020</p>
                                    <div className="row media_content pb-3">
                                        <div className="col-md-11">
                                            <h5>Captech Technologies Launches India's First Construction Labour Marketplace eFORCE</h5>
                                            <a target="_blank" href="https://www.indiainfoline.com/news" className="">Read More</a>
                                        </div>
                                        <div className="col-md-1 media_logo">
                                            <img src="/assets/media-LY.jpg" className="img-fluid" alt="newslogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>





                    </div>
                </div>
            </div>
        </>
    )
}


export default Media;