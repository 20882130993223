import React , { Component } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import section2 from '../assets/banner/banner1.gif'; 
import section22 from '../assets/banner/banner22.png'; 
// import section23 from '../assets/banner/banner3.gif'; 

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true,
      arrows:false
    };
    return (
      <>
      
      <div className="container-fluid" >
        <div className="row">
            <div className="col-md-12">
            <Slider {...settings}>
        <img src={section2} alt="eror" width="100%"/>
         <img src={section22} alt="eror" width="100%"/>
         {/* <img src={section23} alt="eror" width="100%"/> */}
        </Slider>
            </div>
        </div>
      </div>
      
      </>
    );
  }
}


