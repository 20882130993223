import React ,{useEffect}from "react";

import '../styles/Blogs.css';

import userImg from '../assets/section3/t-4.png'
import userImg2 from '../assets/section3/t-3.png'
import userImg3 from '../assets/section3/t-2.png';

import Media from '../components/Media'


function Blogs(){

    useEffect(() => {
        window. scrollTo(0,0);
    }, []);
    return(
    
        <>
            {/* sub heading */}
            <div className="container-fluid blogPage">
                <div className="row">
                    <div className="col-md-12">
                            <h1 className="Contactheading"> Media Coverage</h1>
                   </div>
                </div>
            </div>

  {/* our founder section */}


<Media/>

          
        </>
    )
}


export default Blogs;