import React from "react"; 
import './RealEstate.css';
import Multiselect from 'multiselect-react-dropdown';




function RealEstate(){ 

    let state = {
        options: [{name: 'Hospital', id: 1},{name: 'Roads & Highways', id: 2}]
    };
    
    return(

    
    <>
        {/* sub heading */}
        <div className="container-fluid lcpageSection">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="Contactheading"> Registration for General Contractor(Real Estate)</h1>
                </div>
            </div>
        </div>
    
    
        {/* sub contractor form */}
    
        <div className="container">
            <div className="row">


          {/* Referral */}
          <div className="col-md-12">
                    <br/><br/>
                    <div className="form-section ">
                        <div className="row"> 
                            <div className="col-md-12 ">
                                <h3 className="title">Referral </h3>
    
                                <div className="row">
    
    
                                    <div className="col-md-12">

                                    <div className="row ">
                                  <div className="col-md-6">
                                  <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Name of the Referral<span className="star-color"> &nbsp;*</span></label>
                                            <input type="text" className="form-control" id="inputEmail4" placeholder="Enter Name of the Referral" />
                                        </div>
                                  </div>
                                  <div className="col-md-6">
                                  <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Referral Code</label>
                                            <input type="text" className="form-control" id="inputEmail4" placeholder="Enter Referral Code" />
                                        </div>
                                  </div>
                              </div>
                             
                                    </div>
    
                                </div>
    
    
                            </div>
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
         </div>

          {/* Company Details */}
          <div className="col-md-12">
                    <br/><br/>
                    <div className="form-section ">
                        <div className="row"> 
                            <div className="col-md-12 ">
                                <h3 className="title">Company Details </h3>
    
                                <div className="row">
    
    
                                    <div className="col-md-12">

                                    <div className="row ">
                                  <div className="col-md-6">
                                    <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Company Name (as per PAN Card) <span className="star-color"> &nbsp;*</span></label>
                                            <input type="text" className="form-control" id="inputEmail4" placeholder="Enter Company Name " />
                                    </div>

                                    <div className="form-group "> 
                                    <label className="contentLabel" for="inputEmail4">Entity <span className="star-color"> &nbsp;*</span></label>             
                                    <Multiselect options={state.options} displayValue="name" />           
                                </div>

                                    
                              <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Registered Address <span className="star-color"> &nbsp;*</span></label>
                                            <textarea type="text" className="form-control textArea" id="inputEmail4" placeholder="Enter company address. " />
                             </div>

                                   </div>
                                  <div className="col-md-6">
                                  <div className="form-group "> 
                                    <label className="contentLabel" for="inputEmail4">Specialization <span className="star-color"> &nbsp;*</span></label>             
                                    <Multiselect options={state.options} displayValue="name" />           
                                </div>

                                <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">PAN No  <span className="star-color"> &nbsp;*</span></label>
                                            <input type="number" className="form-control" id="inputEmail4" placeholder="Enter PAN No. " />
                                    </div>
                                <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">GST No  <span className="star-color"> &nbsp;*</span></label>
                                            <input type="number" className="form-control" id="inputEmail4" placeholder="Enter GST No. " />
                                    </div>
                                <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Kharchi Credit Period (In days) <span className="star-color"> &nbsp;*</span></label>
                                             <Multiselect singleSelect options={state.options} displayValue="name" />         
                                               </div>
                                <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">RA Bill Credit Period (In days)  <span className="star-color"> &nbsp;*</span></label>
                                             <Multiselect singleSelect options={state.options} displayValue="name" />         
                              </div>
                                  </div>
                              </div>
                             
                                    </div>
    
                                </div>
    
    
                            </div>
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
         </div>





            {/* project Details */}
                <div className="col-md-6">
                    <br/><br/>
                    <div className="container form-section ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11 ">
                                <h3 className="title">Project Details  </h3>
    
                                <div className="row">
    
    
                                    <div className="col-md-12">
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Name of the Project to be registered<span  className="star-color"> &nbsp;*</span></label>
                                    <input type="text"  className="form-control" id="inputEmail4" placeholder="Enter project  name"/>
     </div>
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4"> Project Type <span  className="star-color"> &nbsp;*</span></label>
                                    <select  className="form-control ">
                                        <option>Please select</option>
                                        <option>Sub Contractor</option>
                                        <option>Labour Contractor</option>
                                      </select>
                              </div>

                              <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Registered Address <span className="star-color"> &nbsp;*</span></label>
                                            <textarea type="text" className="form-control textArea2" id="inputEmail4" placeholder="Enter company address. " />
                             </div>

    

                              <div className="row multiRow"><br/>
                             <h3 className="title">Project Incharge / Subordinate Details  </h3>
                                  
                              </div>

                              <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Name<span  className="star-color"> &nbsp;*</span></label>
                                    <input type="text"   className="form-control" id="inputEmail4" placeholder="Enter project incharge name"/>
                              </div>
                                      
                              <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Mobile number<span  className="star-color"> &nbsp;*</span></label>
                                    <input type="number"   className="form-control" id="inputEmail4" placeholder="Enter mobile number"/>
                              </div>
                                      
                              <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Company Email Address<span  className="star-color"> &nbsp;*</span></label>
                                    <input type="email"   className="form-control" id="inputEmail4" placeholder="Enter Company Email Address"/>
                              </div>
                                      


                         
                                      
    
                                    </div>
    
                                </div>
    
    
                            </div>
    
    
                        </div>
                        <div className="row justify-content-center">
    
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
                </div>

                {/* Site Details */}
                <div className="col-md-6">
                    <br/><br/>
                    <div className="container form-section ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11 ">
                                <h3 className="title">Site  Details </h3>
    
                                <div className="row">
    
    
                                    <div className="col-md-12">
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">No of Storeys<span  className="star-color"> &nbsp;*</span></label>
                                    <input type="number"  className="form-control" id="inputEmail4" placeholder="Enter No Of Storeys"/>
                                    </div>
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Project Current Status<span  className="star-color"> &nbsp;*</span></label>
                                    <select  className="form-control ">
                                        <option>Please select</option>
                                        <option>Mumbai</option>
                                        <option>Navi Mumbai </option>
                                      </select>
                                        </div>
                                        <div className="form-group ">
                                           <label className="contentLabel" for="inputEmail4">Level Completed <span  className="star-color"> &nbsp;*</span></label>
                                           <select  className="form-control ">
                                            <option>Please select</option>
                                            <option>Mumbai</option>
                                            <option>Navi Mumbai </option>
                                        </select>
                                    </div>
                                        <div className="form-group ">
                                           <label className="contentLabel" for="inputEmail4">Labour Camp images and sites images <span  className="star-color"> &nbsp;*</span></label>
                                           <input name=" form-control file-upload-field" type="file" class="file-upload-field" value=""/>
                                    </div>
                                        <div className="form-group ">
                                           <label className="contentLabel" for="inputEmail4">Logistitc Plan of site<span  className="star-color"> &nbsp;*</span></label>
                                           <input name="form-control file-upload-field" type="file" class="file-upload-field" value=""/>
                                    </div>


                                        <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Additional Info (if any)  <span className="star-color"> &nbsp;*</span></label>
                                            <textarea type="text" className="form-control textArea2" id="inputEmail4" placeholder="Enter Additional Info (if any)  " />
                             </div>

                          
                                    </div>
    
                                </div>
    
    
                            </div>
    
    
                        </div>
                        <div className="row justify-content-center">
    
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
                </div>



                {/* Requirement 1 */}

                      <div className="col-md-6">
                    <br/><br/>
                    <div className="container form-section ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11 ">
                                <h3 className="title">Requirement  1 </h3>
    
                                <div className="row">
    
                                    <div className="col-md-12">
                                    
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Activity</label>
                                    <select  className="form-control ">
                                        <option>Please select</option>
                                        <option>Mumbai</option>
                                        <option>Navi Mumbai </option>
                                      </select>
                                        </div>


                                        <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Required Labour Count</label>
                                            <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Labour Count " />
                                       </div>

                                      
                                      


                                     
                          
                                    </div>
            <br/>
                                    <div className="col-md-6">
                                                                    
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Quantity of Work</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Quantity of Work " />
                                   </div>
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Workfront Duration</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Workfront Duration " />
                                   </div>

                                  
                                  


                                 
                      
                                  </div>
                                  <div className="col-md-6">
                                    
                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>Mumbai</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>

                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>KM</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>



                                  
                                  </div>

                                    
    
                                </div>
    
    
                            </div>
    
    
                        </div>
                        <div className="row justify-content-center">
    
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
                     </div>
                {/* Requirement 2 */}

                      <div className="col-md-6">
                    <br/><br/>
                    <div className="container form-section ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11 ">
                                <h3 className="title">Requirement  2 </h3>
    
                                <div className="row">
    
                                    <div className="col-md-12">
                                    
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Activity</label>
                                    <select  className="form-control ">
                                        <option>Please select</option>
                                        <option>Mumbai</option>
                                        <option>Navi Mumbai </option>
                                      </select>
                                        </div>


                                        <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Required Labour Count</label>
                                            <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Labour Count " />
                                       </div>

                                      
                                      


                                     
                          
                                    </div>
            <br/>
                                    <div className="col-md-6">
                                                                    
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Quantity of Work</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Quantity of Work " />
                                   </div>
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Workfront Duration</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Workfront Duration " />
                                   </div>

                                  
                                  


                                 
                      
                                  </div>
                                  <div className="col-md-6">
                                    
                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>Mumbai</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>

                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>KM</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>



                                  
                                  </div>

                                    
    
                                </div>
    
    
                            </div>
    
    
                        </div>
                        <div className="row justify-content-center">
    
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
                     </div>
                {/* Requirement 3 */}

                      <div className="col-md-6">
                    <br/><br/>
                    <div className="container form-section ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11 ">
                                <h3 className="title">Requirement  3 </h3>
    
                                <div className="row">
    
                                    <div className="col-md-12">
                                    
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Activity</label>
                                    <select  className="form-control ">
                                        <option>Please select</option>
                                        <option>Mumbai</option>
                                        <option>Navi Mumbai </option>
                                      </select>
                                        </div>


                                        <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Required Labour Count</label>
                                            <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Labour Count " />
                                       </div>

                                      
                                      


                                     
                          
                                    </div>
            <br/>
                                    <div className="col-md-6">
                                                                    
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Quantity of Work</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Quantity of Work " />
                                   </div>
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Workfront Duration</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Workfront Duration " />
                                   </div>

                                  
                                  


                                 
                      
                                  </div>
                                  <div className="col-md-6">
                                    
                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>Mumbai</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>

                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>KM</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>



                                  
                                  </div>

                                    
    
                                </div>
    
    
                            </div>
    
    
                        </div>
                        <div className="row justify-content-center">
    
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
                     </div>
                {/* Requirement 4 */}

                      <div className="col-md-6">
                    <br/><br/>
                    <div className="container form-section ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11 ">
                                <h3 className="title">Requirement  4 </h3>
    
                                <div className="row">
    
                                    <div className="col-md-12">
                                    
                                        <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Activity</label>
                                    <select  className="form-control ">
                                        <option>Please select</option>
                                        <option>Mumbai</option>
                                        <option>Navi Mumbai </option>
                                      </select>
                                        </div>


                                        <div className="form-group ">
                                            <label className="contentLabel" for="inputEmail4">Required Labour Count</label>
                                            <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Labour Count " />
                                       </div>

                                      
                                      


                                     
                          
                                    </div>
            <br/>
                                    <div className="col-md-6">
                                                                    
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Quantity of Work</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Quantity of Work " />
                                   </div>
                                    <div className="form-group ">
                                        <label className="contentLabel" for="inputEmail4">Workfront Duration</label>
                                        <input type="number" className="form-control" id="inputEmail4" placeholder="Enter Workfront Duration " />
                                   </div>

                                  
                                  


                                 
                      
                                  </div>
                                  <div className="col-md-6">
                                    
                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>Mumbai</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>

                                    <div className="form-group ">
                                    <label className="contentLabel" for="inputEmail4">Unit</label>
                                <select  className="form-control ">
                                    <option>Please select</option>
                                    <option>KM</option>
                                    <option>Navi Mumbai </option>
                                  </select>
                                    </div>



                                  
                                  </div>

                                    
    
                                </div>
    
    
                            </div>
    
    
                        </div>
                        <div className="row justify-content-center">
    
                        </div>
                        <br/><br/>
                    </div>
                    <br/><br/>
                     </div>



              
              

            </div>
        </div>
    
    
        </> ) } export default RealEstate;