import react, { useState, useEffect, lazy } from "react";
import './HomePage.css';
import LogoSection from './LogoSection';


// import imges
import Bulb from './assets/bulbgif.gif';
// import Bulb from './assets/Bulb.png';
import section2 from './assets/section-2.png';
import section3 from './assets/eforce-poster.png';
import sec_5_1 from './assets/s5-1.png';
import section_5 from './assets/section-5.png';
import sec_5_2 from './assets/s5-2.png';
import sec_5_3 from './assets/s5-3.png';
import sec_6_1 from './assets/keybnenefits/1.png';
import sec_6_2 from './assets/keybnenefits/2.png';
import sec_6_3 from './assets/keybnenefits/3.png';
import sec_10_mob from './assets/mob-dashboard.png';
import sec_10_playstor from './assets/astore.png'
import sec_10_appstor from './assets/gplay.png'

import Carausel_section from './components/Carausel_section';
import Testimonial_Section from './components/Testimonial';

import Membership from './components/Membership';

import MediaCoverage from './components/MediaCoverage';
import HowToSimpleSlider from './components/HowTo';

import Faq from './components/Faq'

// gif section

import gif1 from './assets/process.gif'
import gif2 from './assets/hiring_process.gif'
import gif3 from './assets/eforce-flow.gif'



import Typical from 'react-typical'



function HomePage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (



        <>
            <div className="container">
                <div className="row">
                    {/* section 1 */}
                    <div className="col-md-12">
                        <ul className="maincontent">
                            <li>
                                <h1 className="heading">Introducing a B2B Platform</h1>
                            </li>
                            <li>
                                <img src={Bulb} className="bulb_img" alt="bulb" />
                            </li>

                            <div className="subContent">
                                <h2 className="eforce">eFORCE</h2>
                                <p>India’s First Labour Management Platform</p>

                                {/* <Typical 
                                    steps={['Introducing a B2B Platform', 1000, 'Construction is the second largest employer in India and is facing great uncertainty during these times.', 500]}
                                    loop={Infinity}
                                    wrapper="p"
                                /> */}
                                <a href="#keyBenifits"><button className="btn btn-4">Key Benefits</button></a>
                            </div>
                        </ul>


                    </div>



                </div>
            </div>

            <div className="container-fluid mobile-top-spece">
                <div className="row">
                    {/* section-2 */}
                    <div className="col-xl-12 col-12">

                        <Carausel_section />
                    </div>
                </div>
            </div>

            {/* <div className="container-fluid section_3">
                <div className="row">

                    <LogoSection />
                </div>
            </div> */}

            {/* <div className="container-fluid section_4 mt-4">
                <div className="container">
                    <div className="row ">
                        {/* section 3 slider for logos */} {/* section 4 reduce your delay in your construction */}
            {/* <div className="col-md-6 ">
                            <p className="sec_4_content">Reduce delays in your construction <br />projects - find the <br />“right fit Sub Contractors” for your project site.</p>
                            <button className="btn btn-1 sec_4_ContentButton ">EXPLORE</button>
                        </div>
                        <div className="col-md-6 ">
                            <a href="#"><img className="sec_4_content" src={section3} alt="section_3" width="100%" /></a>
                        </div> */}

            {/* </div>
                </div>
            </div> */}




            {/* section 6 */}
            <div className="container" id="keyBenifits">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="sec_6_header">Key Benefits</h2>
                        <p className="sec_6_content">eFORCE by Captech Technologies Pvt Ltd is India’s first Labour Management Platform designed to enhance collaboration between General Contractors and Labour Contractors. Easy to use, fully secured, end-to-end, enterprise business solution for the Infrastructure Industry. </p>
                    </div>

                    <div className="col-sm-4 princing-item">
                        <div className="card-body bg-white mt-0 shadow content-body">
                            <img src={sec_6_1} className="s6-img" />
                            <br />
                            <h3 className="s6title">Web & Mobile</h3>
                            <p className="s6subTitle">
                                Role based usage of this secure application in different formats Web, Android phones or iOS Phones. Therefore, you don’t need to be at your desk and can “WORK on the GO”
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-4 princing-item">
                        <div className="card-body bg-white mt-0 shadow content-body">
                            <img src={sec_6_2} className="s6-img" />
                            <h3 className="s6title">Real-time Information</h3>
                            <p className="s6subTitle">
                                We believe, those days as passé when you get information only when it is emailed to you. It’s time to gear up for “Real time” information and see things as they unfold
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-4 princing-item">
                        <div className="card-body bg-white mt-0 shadow content-body">
                            <img src={sec_6_3} className="s6-img" />
                            <h3 className="s6title">Building Trust</h3>
                            <p className="s6subTitle">
                                We assist in Trust building between Buyer and Seller by being Transparent on issues that matter most.
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            {/* section 5 KEY BENIFITS */}

            <div className="container-fluid section_5">
                <div className="row">
                    <div className="col-md-6">
                        <div className="section_5_content-left" style={{top:'42%'}}>
                            <h2 className="key2_benefits mb-3">Key Features for Multi-Lingual</h2>
                            <p className="text-dark mb-1 mt-3 font-change"><strong className="text-dark">eFORCE</strong> is a Multi-Lingual interface currently available in  <strong className="text-dark">English, Hindi, Marathi and Bangla</strong> .</p>
                            <button className="btn btn-1 sec_5_ContentButton ">EXPLORE</button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <img src="/assets/section-1.png" className="sec_5_img img-fluid" alt="Membership" width={'auto'} style={{ padding: '0px' }} />
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="container-fluid section_6">
                <div className="row">
                    <div className="col-md-5">
                        <div className="row">
                            <img src="/assets/section-2.png" className="sec_5_img" alt="Membership" width={'auto'} style={{ padding: '0px' }} />
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="section_5_content">
                            <h2 className="key2_benefits mb-3">Key Features for Sub Contractors / Labour Contractors</h2>
                            <ul className="m-0 custom-list">
                                <li className="text-dark mb-2">Labour Availability: Showcase your available workforce categorized / counts by skill set.</li>
                                <li className="text-dark mb-2">Efficient Communication: Stay in seamless contact with the site team for project updates and needs.</li>
                                <li className="text-dark mb-2">Real-Time Labour Requirements: View real-time labour needs for all ongoing projects within the organization, covering everything from Civil to MEP.</li>
                                <li className="text-dark mb-2">Access and view project details</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}

<div className="container-fluid section_6">
    <div className="row align-items-center">
        <div className="col-md-6 d-flex align-items-center justify-content-center mobile-col-md-space">
            <img src="/assets/section-2.png" className="sec_5_img sec_5_img-mobile img-fluid" alt="Membership" width={'auto'} style={{ padding: '0px' }} />
        </div>
        <div className="col-md-6 d-flex align-items-center">
            <div className="section_5_content d-flex flex-column justify-content-center w-100">
                <h2 className="key2_benefits mb-3 mobile-label-top-space">Key Features for Sub Contractors / Labour Contractors</h2>
                <ul className="m-0 custom-list">
                    <li className="text-dark mb-2">Labour Availability: Showcase your available workforce categorized / counts by skill set.</li>
                    <li className="text-dark mb-2">Efficient Communication: Stay in seamless contact with the site team for project updates and needs.</li>
                    <li className="text-dark mb-2">Real-Time Labour Requirements: View real-time labour needs for all ongoing projects within the organization, covering everything from Civil to MEP.</li>
                    <li className="text-dark mb-2">Access and view project details</li>
                </ul>
            </div>
        </div>
    </div>
</div>


            {/* <div className="container-fluid section_5">
                <div className="row">
                    <div className="col-md-7">
                        <div className="section_5_content-left" style={{top:'3%'}}>
                            <h2 className="key2_benefits mb-2">Key Features for General Contractors</h2>
                            <ul className="m-0 custom-list">
                            <li className="text-dark mt-2">Contractor Pool Management: Have a list of eKYC verified contractors with compliance details easily accessible.</li>
                            <li className="text-dark mt-2"> Seamless Coordination: Effortless communication with contractors for smooth project execution.</li>
                            <li className="text-dark mt-2"> Smart Search: Upload requirements and find contractors from your existing pool of sub-contractors and labour contractors.</li>
                            <li className="text-dark mt-2"> Project Overview Sharing: Provide detailed project overviews to subcontractors, labour contractors, and key personnel, including structure details, site & camp images, facilities, and logistics plans.</li>
                            <li className="text-dark mt-2"> Labour Reports: Access real-time labour reports to track workforce performance and project progress.</li>
                            <li className="text-dark mt-2"> Digital Contractor Evaluation: Digitally assess and evaluate subcontractors and labour contractors, improving onboarding and decision-making efficiency.</li>
                            </ul>
                            
                            <button className="btn btn-1 sec_5_ContentButton ">EXPLORE</button>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="row">
                            <img src="/assets/section-3.png" className="sec_5_img" alt="Membership" width={'auto'} style={{ padding: '0px' }} />
                        </div>
                    </div>
                </div>
            </div> */}

<div className="container-fluid section_5">
    <div className="row align-items-center">
        <div className="col-md-6 d-flex align-items-center">
            <div className="section_5_content-left d-flex flex-column justify-content-center w-100" style={{ minHeight: '100%' }}>
                <h2 className="key2_benefits mb-2 mobile-label-top-space">Key Features for General Contractors</h2>
                <ul className="m-0 custom-list">
                    <li className="text-dark mt-2">Contractor Pool Management: Have a list of eKYC verified contractors with compliance details easily accessible.</li>
                    <li className="text-dark mt-2">Seamless Coordination: Effortless communication with contractors for smooth project execution.</li>
                    <li className="text-dark mt-2">Smart Search: Upload requirements and find contractors from your existing pool of sub-contractors and labour contractors.</li>
                    <li className="text-dark mt-2">Project Overview Sharing: Provide detailed project overviews to subcontractors, labour contractors, and key personnel, including structure details, site & camp images, facilities, and logistics plans.</li>
                    <li className="text-dark mt-2">Labour Reports: Access real-time labour reports to track workforce performance and project progress.</li>
                    <li className="text-dark mt-2">Digital Contractor Evaluation: Digitally assess and evaluate subcontractors and labour contractors, improving onboarding and decision-making efficiency.</li>
                </ul>
                <button className="btn btn-1 sec_5_ContentButton mt-3">EXPLORE</button>
            </div>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center mobile-col-md-space">
            <img src="/assets/section-3.png" className="sec_5_img sec_5_img-mobile img-fluid" alt="Membership" width={'auto'} style={{ padding: '0px' }} />
        </div>
    </div>
</div>



            {/* gif section */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12" style={{ padding: '0', backgroundColor: '#141414' }}>
                        <h3 className="gif_Header">Market Process</h3>
                        <img src={gif2} width="100%" className="img-fluid" />
                        <h3 className="gif_Header">Our Process</h3>
                        <p className="sec_6_content">eFORCE app provides an organized and userfriendly interface which helps both developersand labour contractors connect with each other, hence reducing the hiring time to 1/3rd</p>
                        <img src={gif3} width="100%" className="img-fluid" />
                        <h3 className="gif_Header">Our Impact</h3>
                        <img src={gif1} width="100%" className="img-fluid" />
                    </div>
                </div>
            </div>


            {/* section 7  our process */}

            <div className="container-fluid our_process">

                <div className="container section_7_first">
                    {/* <h1>hie</h1> */}
                </div>

            </div>

            {/* section10  download now */}

            <div className="container-fluid download_now">

                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="download_content">
                                <h4 className="downloadNow">Download Now</h4>
                                <p className="downloadNow_sub">Register to use our platform - now</p>
                                <div className="row" >

                                    <div className="col-sm-6 playstore_link" >
                                        <a target="_blank" href="https://apps.apple.com/in/app/eforce-by-captech/id1515684199"> <img src={sec_10_playstor} alt="platstorimg" width='100%' /></a>
                                    </div>
                                    <div className="col-sm-6">
                                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.eforce.eforce_pi&hl=en_IN&gl=US"> <img src={sec_10_appstor} alt="platstorimg" className="android-playstore" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 text-end">
                            <img src={sec_10_mob} alt="mobile" className="img-fluid playstore-app" />
                        </div>
                    </div>
                </div>
            </div>


            <Testimonial_Section />


            <MediaCoverage />
            <HowToSimpleSlider /><br></br><br></br>

            {/* <Membership /> */}
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="sec_6_header">Membership</h1>
                        <img src="/assets/membership.png" className="member-div" alt="Membership" />


                    </div>
                </div>
            </div>
            <Faq />


        </>)
}

export default HomePage;