import React, { useEffect, useState, Component } from "react";
import { safePreventDefault } from "react-slick/lib/utils/innerSliderUtils";
import '../styles/LCForm.css';
import axios from "axios";
import { set, useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { render } from "@testing-library/react";
import Spinner from "../components/Spinner";
import samplePanImg from '../assets/sample-pan-card-no.png'
import sampleGSTImg from '../assets/sample-gst-no.png'
import { FaCheckCircle, FaInfoCircle, FaSearch } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
let state = {
    options: [{ name: 'Hospital', id: 1 }, { name: 'Roads & Highways', id: 2 }]
};

let projectOptions = [
    <option value='null' key="null" >Please select</option>
];
let cityOptions = [
    <option value='null' key="null" >Please select</option>
];
let entityOptions = [
    <option value='null' key="null" >Please select</option>
];
let typeOfContractorOptions = [
    <option value='null' key="null" >Please select</option>
];

let CityList = [];

let stateName = "";

let multProject = {
    options: []
}

let project_type_data = []


// base url

// const PANGATUrl = "http://3.7.163.243:4200/"
const PANGATUrl = "https://eforce.cap-tech.in:4810/"


function LCForm() {


    let ProjectmultiselectRef = React.createRef()
    let citymultiselectRef = React.createRef()
    let geotmultiselectRef = React.createRef()
    let preferedtmultiselectRef = React.createRef()

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm();


    //   all state variable
    const [project_type, setProject_type] = useState([]);
    const [entity_type, setEntity_type] = useState([]);
    const [contractor_type, setContractor_type] = useState([]);
    const [unique_identification, setUnique_Identification] = useState([]);
    const [city_type, setCity_type] = useState([]);
    const [stateName, setStateName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // multiple select values
    const [projectTypeSelect, setprojectTypeSelect] = useState([]);
    const [PreferdlocationSelect, setPreferdlocationSelect] = useState([]);
    const [GeolocationSelect, setGeolocationSelect] = useState([]);
    const [CitySelect, setCitySelect] = useState([]);

    const [PANNumber, setPANNumber] = useState();
    const [GSTNumber, setGSTNumber] = useState();

    const [companyName, setcompanyName] = useState();
    const [ownerName, setcOwnerName] = useState();
    // error useState

    const [errorPAN, setErrorPAN] = useState();
    const [panVerificationStatus, setpanVerificationStatus] = useState(true);
    const [gstVerificationStatus, setgstVerificationStatus] = useState(true);

    //pan gst hover
    const [showTooltipPan, setShowTooltipPan] = useState(false);
    const [showTooltipGST, setShowTooltipGST] = useState(false);
    const [showSearchPan, setShowSearchPan] = useState(false);
    const [PANNumberCheck, setPANNumberCheck] = useState();
    const [PANNameCheck, setPANNameCheck] = useState();

    const [urlUid, setUrlUid] = useState();

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        ProjectType();
        getEntity();
        getContractorType();
        getCity();
        getUniqueIdentification();

        console.log(location, 'location');
        if (location && location.pathname) {
            if (location.pathname.includes('/lc/src/')) {
                const urlParts = location.pathname.split("/lc/src/");
                setUrlUid(urlParts?.[1] || 0);
            }
        }
    }, [location]);

    // get all data

    // PROJECT TYPE GET
    const ProjectType = () => {

        try {

            // axios.get("http://3.7.163.243:4100/project_type/get/?is_active=True&show=All").then(res => {
            axios.get("https://eforce.cap-tech.in:4810/project_type/get/?is_active=True&show=All").then(res => {    
                setProject_type(res.data.data.results);
            });

        } catch (error) {
            console.log("error of project type-->", error);
        }

    }

    // ENTITY TYPE GET
    const getEntity = () => {

        try {

            // axios.get("http://3.7.163.243:4800/entity/get/").then(res => {
            axios.get("https://eforce.cap-tech.in:4810/entity/get/").then(res => {    
                //   console.log("get entity",res);
                setEntity_type(res.data.data)

            });

        } catch (error) {
            console.log("error of project type-->", error);
        }

    }
    // ENTITY TYPE GET
    const getContractorType = () => {
        try {
            // axios.get("http://3.7.163.243:4800/material_types/get/").then(res => {
            axios.get("https://eforce.cap-tech.in:4810/material_types/get/").then(res => {    
                setContractor_type(res.data.data)
            });
        } catch (error) {
            console.log("error of contractor type-->", error);
        }

    }
    // Unique Identification GET
    const getUniqueIdentification = () => {
        try {
            // axios.get("http://3.7.163.243:4100/unique-identification/get/").then(res => {
            axios.get("https://eforce.cap-tech.in:4810/unique-identification/get/?is_active=1&show=All").then(res => {    
                setUnique_Identification(res.data.data.results)
            });
        } catch (error) {
            console.log("error of Unique_Identification type-->", error);
        }

    }

    // GET CITY

    const getCity = () => {
        try {
            // axios.get("http://3.7.163.243:4800/city/get/").then(res => {
            axios.get("https://eforce.cap-tech.in:4810/city/get/").then(res => {    

                setCity_type(res.data.data)
            });

        } catch (error) {
            console.log("city error", error);
        }
    }

    // form usesate

    const [formData, formDataFunction] = useState({
        company_name: '',
        address: '',
        entity_id: '',
        terms: '',
        city_id: '',
        pincode: '',
        no_of_projects_completed: '',
        gst_no: '',
        annual_turnover: '',
        pan_no: '',
        pf_no: '',
        type_of_contractor_id: '', unique_identification_id: '',
        description_of_work: '',
        website: '',
        esic: '',
        certification: '',
        source: '',
        whats_app_no: '',
        project_type: '',
        geo_spred: '',
        preferred_location: '',
        name: '',
        email_address: '',
        mobile_no: '',
        dob: '',
        tc_accepted: '',
        whatsapp_accepted: '',
        department: 'LC',
        language: 'en-US',
        project_type_ids: '',
        //  refrence section

        PIName1: '',
        Companyname1: '',
        Projectname1: '',
        mob1: '',
        PIName2: '',
        Companyname2: '',
        Projectname2: '',
        mob2: '',
        whatsapp_accepted: '', tc_accepted: ''

    });

    // form submit
    const onSubmitForm = (data) => {
        setIsLoading(true)
        console.log("form data", data);

        // ProjectmultiselectRef.current.resetSelectedValues();
        // citymultiselectRef.current.resetSelectedValues();
        // geotmultiselectRef.current.resetSelectedValues();
        // preferedtmultiselectRef.current.resetSelectedValues();
        // preferedtmultiselectRef.current.resetSelectedValues()

        console.log("pan number ", PANNumber);

        console.log("company name", companyName);

        let obj = {
            address: data.address,
            annual_turnover: data.annual_turnover ? data.annual_turnover : null,
            certification: data.certification,
            city_id: CitySelect[0],
            company_name: data.company_name,
            description_of_work: data.description_of_work,
            entity_id: data.entity_id,
            esic: data.esic,
            unique_identification_id: data.unique_identification_id,

            gst_no: data.gst_no,
            no_of_projects_completed: data.no_of_projects_completed ? data.no_of_projects_completed : 0,

            pan_no: data.pan_no,
            pf_no: data.pf_no,
            pincode: data.pincode,

            source: urlUid ? urlUid : "0",
            type_of_contractor_id: data.type_of_contractor_id,
            website: data.website,
            whats_app_no: data.whats_app_no,
            project_type: [{ project_type_ids: projectTypeSelect }],
            geo_spred: [{ location_ids: GeolocationSelect && GeolocationSelect.length > 0 ? GeolocationSelect : '' }],
            preferred_location: [{ location_ids: PreferdlocationSelect }],
            owner_details:
            {
                name: data.name,
                email_address: data.email_address,
                mobile_no: data.mobile_no ? parseInt(data.mobile_no) : null,
                dob: data.dob ? data.dob : null,
                // tc_accepted: true,
                role: { role: 3 },
                department: "LC",
                language: "en-US",
                tc_accepted: data.tc_accepted,
                whatsapp_accepted: data.whatsapp_accepted,
            },
            reference: [

                {
                    ref_company_name: data.Companyname1,
                    ref_contact_no: data.mob1,
                    ref_name: data.PIName1,
                    ref_project_name: data.Projectname1,
                    seq_no: 1,
                },
                {
                    ref_company_name: data.Companyname2,
                    ref_contact_no: data.mob2,
                    ref_name: data.PIName2,
                    ref_project_name: data.Projectname2,
                    seq_no: 2,
                }],
        }

        validateData(obj)

        console.log(" final data-->", obj)

        // onSubmit(obj)

        PANNumberverfication(obj, companyName)

        // console.log("pan verification status", panVerificationStatus);

    }

    const PANNumberverfication = (obj, companyName) => {
        try {
            axios.post(PANGATUrl + "pan_verify/", {
                "pan_no": PANNumber
            }).then(res => {

                let resData = res.data['data'][0]
                console.log("pan result", resData);

                if (resData['response_code'] == '100') {
                    // ////console.log("campany anme->", this.companyName);

                    let panCompanyName = resData['result'];
                    let split = panCompanyName['user_full_name'].split(" ")

                    if (panCompanyName['pan_status'] == "VALID") {

                        console.log("iner pam", companyName);

                        for (let i = 0; i < companyName.length; i++) {

                            let uppercar = split[0].toUpperCase()

                            console.log("uppar case", uppercar);

                            if (uppercar == companyName[i]) {

                                console.log("verifiy by company name");

                                setpanVerificationStatus(true);

                                onSubmit(obj)

                                return
                            }
                            else {

                                console.log("not match with company name");
                                setIsLoading(false)
                                setpanVerificationStatus(false)

                            }

                        }

                        let uppercar1 = split[0].toUpperCase()
                        console.log("company name", ownerName, uppercar1);

                        for (let i = 0; i < ownerName.length; i++) {
                            if (uppercar1 == ownerName[i]) {
                                console.log("verfied user from owner by woner name ");

                                setpanVerificationStatus(true)

                                onSubmit(obj)

                                return
                            }
                            else {
                                console.log("Unauthrised user identify by pan");
                                setIsLoading(false)
                                setpanVerificationStatus(false)

                            }

                        }

                        // if (this.PanVerifiedStatus) {


                        // }
                        // else {
                        //     alert("PAN details doesnot match with name or company name")

                        // }

                    }


                }
                else {
                    setIsLoading(false)
                    if (resData['response_code'] == 99 || 100 || 105 || 110 || 104) {

                        alert(" Somthing went wrong");

                    }

                    if (resData['response_code'] == 102) {
                        alert("PAN details doesn't match with name or company name");
                    }
                    else {
                        alert("Invalid PAN details");
                    }
                }


            })
        } catch (error) {
            setIsLoading(false)
            console.log("somting went wrong", error);
        }
    }

    const PANNoCheck = (PanNumberCheck) => {
        try {
            axios.post(PANGATUrl + "pan_verify/", {
                "pan_no": PanNumberCheck
            }).then(res => {

                let resData = res.data['data'][0]
                console.log("pan result", resData);
                if (resData['response_code'] == '100') {
                    let user_full_name = res.data['data'][0]?.['result']?.['user_full_name']
                    console.log(user_full_name, 'name')
                    setPANNameCheck(user_full_name)
                }
                else {
                    setIsLoading(false)
                    if (resData['response_code'] == 99 || 100 || 105 || 110 || 104) {
                        alert(" Somthing went wrong");
                    }
                    else {
                        alert("Invalid PAN details");
                    }
                }


            })
        } catch (error) {
            setIsLoading(false)
            console.log("somting went wrong", error);
        }
    }
    const GSTverification = (obj) => {
        try {

            axios.post(PANGATUrl + "gst_verify/", {
                "gst_no": GSTNumber
            }).then(result => {

                let res = result['data']['data'][0];
                if (res['response_code'] == "100") {
                    let GstVerifiedName = res['result']['trade_name'];
                    let split = GstVerifiedName.toUpperCase().split(" ");

                    for (let i = 0; i < companyName.length; i++) {
                        if (split[0] == companyName[i]) {

                            console.log("gst verifoed");
                            setgstVerificationStatus(true)
                            setIsLoading(false)
                            return
                        }
                        else {
                            setgstVerificationStatus(false)
                            setIsLoading(false)
                            console.log("not verified");

                        }


                    }


                }
                else {


                    setIsLoading(false)
                    alert("Somthing Went Wrong...😅😄 please try again 😁")

                }

            })

        } catch (error) {
            setIsLoading(false)
            console.log("error", error);
        }
    }

    // all validation here

    const validateData = (obj) => {

        if (obj.city_id == null || obj.city_id == undefined) {
            alert("City should not be blank")
        } else {
            if (obj.preferred_location[0]['location_ids'][0].length == 0) {
                alert("Preferred location should not be blank")
            } else {
                if (obj.project_type[0]['project_type_ids']['length'] == 0) {
                    alert('Project type should not be blank')
                } else {
                    if (obj.entity_id == null || obj.entity_id == ' ') {
                        alert('Entity type should not be blank')
                    } else {
                        if (obj.type_of_contractor_id == null || obj.type_of_contractor_id == ' ') {

                            alert('Type of contractor should not be blank')
                        } else {
                            console.log("form validate sucseefully");
                        }
                    }
                }
            }
        }

    }
    async function onSubmit(data) {
        console.log(data, 'submit')
        setIsLoading(true)
        if (gstVerificationStatus) {
            const submit = await axios.post("https://eforce.cap-tech.in:4810/sub_contractor/create/", data).then((res) => {
                console.log("api responser--", res)
                setIsLoading(false)
                reset();
                // const result =  fetch('./api/formValues.json'); // result: { firstName: 'test', lastName: 'test2' }
                // reset(result); // asynchronously reset your form values


                alert('You are succesfully registered..!')

            }).catch((err) => {
                setIsLoading(false)
                console.log("error--", err)
                alert("somthing went wrong please try again")
            });
        } else {
            setIsLoading(false)
            alert('Please Enter valid GST Number...🤗')
        }
    }

    return (

        <>
            {isLoading && <Spinner></Spinner>}
            {/* sub heading */}
            <div className="container-fluid lcpageSection">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Contactheading"> Registration for Sub Contractor</h1>
                    </div>
                </div>
            </div>


            {/* sub contractor form */}

            <div className="container">
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="row">




                        {/* company Details */}
                        <div className="col-md-6">
                            <br /><br />
                            <div className="container form-section ">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-11 ">
                                        <h3 className="title">Company Details </h3>

                                        <div className="row">


                                            <div className="col-md-12">
                                                <div className="form-group ">
                                                    <label className="contentLabel">Company Name (as per PAN card)<span className="star-color"> &nbsp;*</span></label>
                                                    <input maxLength={100} type="text" {...register("company_name", {
                                                        required: true, onBlur: (e) => {
                                                            let Name = e.target.value.toUpperCase()
                                                            let CName = Name.split(" ");
                                                            setcompanyName(CName)
                                                        },
                                                    })} className="form-control" id="inputEmail4" placeholder="Enter Company Name" />
                                                    {errors.company_name && (
                                                        <span className="errorMsg">
                                                            Company Name is a required field
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group ">
                                                    <label className="contentLabel">Type of Legal Entity<span className="star-color"> &nbsp;*</span></label>
                                                    <select className="form-control" style={{ lineHeight: '20px' }}   {...register("entity_id", { required: true })} >
                                                        <option value=' ' key='asd'>Please select</option>
                                                        {

                                                            entity_type ? entity_type.map((item) => (
                                                                <option value={item.id} key={item.id}>{item.entity_name}</option>
                                                            )) : <option value=' ' key='asd'>Please select</option>



                                                        }


                                                    </select>
                                                    {errors.entity_id && (
                                                        <span className="errorMsg">
                                                            Company Name is a required field
                                                        </span>
                                                    )}
                                                </div>


                                                <div className="row multiRow">
                                                    <div className="col-md-6">
                                                        <div className="form-group" style={{ position: 'relative' }}>
                                                            {showTooltipPan && (
                                                                <img style={{ position: 'absolute', top: '-120px' }}
                                                                    src={samplePanImg}
                                                                    alt="Tooltip"
                                                                    width={200}
                                                                />
                                                            )}
                                                            <label className="contentLabel">PAN No<span className="star-color"> &nbsp;*</span>
                                                                <span
                                                                    className="cursor-pointer text-blue-500" style={{ cursor: "pointer", marginRight: '10px' }}
                                                                    onClick={() => setShowTooltipPan(!showTooltipPan)}
                                                                >
                                                                    <FaInfoCircle size={20} />
                                                                </span>
                                                                <span onClick={() => {
                                                                    setShowSearchPan(true)
                                                                    setPANNameCheck('')
                                                                    setPANNumberCheck('')
                                                                }} >
                                                                    <FaSearch />
                                                                </span>
                                                            </label>
                                                            <input maxLength={10} type="text" onKeyPress={(event) => {
                                                                console.log("key press");
                                                            }} onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}  {...register("pan_no", {
                                                                required: true,

                                                                onBlur: (e) => {
                                                                    console.log("PAN BLUR VAL", e.target.value);

                                                                    if (e.target.value.length == 10) {
                                                                        setPANNumber(e.target.value)

                                                                    }
                                                                },
                                                                pattern: {
                                                                    value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                                                    message: "Invalid PAN Number",
                                                                },
                                                            })} pattern="^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$" className="form-control" id="inputEmail4" placeholder="Enter your PAN No." />
                                                            {errors.pan_no && (
                                                                <span className="errorMsg" role="alert">
                                                                    {errors.pan_no.message}
                                                                </span>
                                                            )}
                                                            <span style={{ color: 'red' }}>{panVerificationStatus ? " " : " INVALID PAN "}</span>

                                                            <span>{errorPAN}</span>

                                                        </div>
                                                    </div>
                                                    <Modal show={showSearchPan} onHide={() => {
                                                        setShowSearchPan(false)
                                                        setPANNameCheck('')
                                                        setPANNumberCheck('')
                                                    }} centered>
                                                        <Modal.Header closeButton style={{ background: '#f47d35', margin: 0, padding: '20px 0px 20px 0px;' }}>
                                                            <FaCheckCircle style={{ margin: '0 auto' }} fill="white" stroke="white" size={80} />
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div style={{ textAlign: 'center' }}>
                                                                <h1 className="label-dark">Great</h1>
                                                                <h5 className="label-dark">You can check your PAN details.</h5>
                                                            </div>
                                                            <input maxLength={10} type="text" onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}  {...register("pan_no", {
                                                                required: true,
                                                                onBlur: (e) => {
                                                                    if (e.target.value.length == 10) {
                                                                        setPANNumberCheck(e.target.value)
                                                                        PANNoCheck(e.target.value)
                                                                    }
                                                                },
                                                                pattern: {
                                                                    value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                                                    message: "Invalid PAN Number",
                                                                },
                                                            })} pattern="^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$" className="form-control" id="inputEmail4" placeholder="Enter your PAN No." />

                                                            <label>Name (as per PAN card)</label>
                                                            <input type="text" className="form-control" value={PANNameCheck} style={{ background: 'white' }} disabled placeholder="Enter Pan No" />
                                                        </Modal.Body>
                                                    </Modal>
                                                    <div className="col-md-6">
                                                        <div className="form-group" style={{ position: 'relative' }}>
                                                            {showTooltipGST && (
                                                                <img style={{ position: 'absolute', top: '-120px' }}
                                                                    src={sampleGSTImg}
                                                                    alt="Tooltip"
                                                                    width={200}
                                                                />
                                                            )}
                                                            <label className="contentLabel">GST No
                                                                <span
                                                                    className="cursor-pointer text-blue-500" style={{ cursor: "pointer", marginLeft: '10px' }}
                                                                    onClick={() => setShowTooltipGST(!showTooltipGST)}
                                                                >
                                                                    <FaInfoCircle size={20} />
                                                                </span>

                                                            </label>
                                                            <input   {...register("gst_no", {
                                                                required: false,
                                                                onBlur: (e) => {
                                                                    console.log("PAN BLUR VAL", e.target.value);
                                                                    if (e.target.value.length == 15) {

                                                                        setGSTNumber(e.target.value)

                                                                        GSTverification();

                                                                    }
                                                                },
                                                                pattern: {
                                                                    value:
                                                                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
                                                                    message: "Invalid GST ",
                                                                },
                                                            })} type="text" maxLength={15} className="form-control" id="inputEmail4" placeholder="Enter your GST No." />

                                                            {errors.gst_no && (
                                                                <span className="errorMsg" role="alert">
                                                                    {errors.gst_no.message}
                                                                </span>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group ">
                                                    <label className="contentLabel">Annual Agg. Turnover</label>
                                                    <input {...register("annual_turnover")} type="text" className="form-control" id="inputEmail4" placeholder="Annual Agg. Turnover" />
                                                </div>



                                                <div className="row multiRow">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">PF No</label>
                                                            <input  {...register("pf_no", {
                                                                pattern: {
                                                                    value: /^[A-Z]{5}[0-9]{7}[0-9]{3}[0-9]{7}$/,
                                                                    message: "Invalid PF Number",
                                                                },
                                                            })} type="text" className="form-control" id="inputEmail4" placeholder="Enter your PF No." />

                                                            {errors.pf_no && (
                                                                <span className="errorMsg" role="alert">
                                                                    {errors.pf_no.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">ESIC</label>
                                                            <input   {...register("esic")} type="number" className="form-control" id="inputEmail4" placeholder="Enter your ESIC No." />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>


                                    </div>


                                </div>
                                <div className="row justify-content-center">

                                </div>
                                <br /><br />
                            </div>
                            <br /><br />
                        </div>

                        {/* company address */}
                        <div className="col-md-6">
                            <br /><br />
                            <div className="container form-section ">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-11 ">
                                        <h3 className="title">Registered Company Address</h3>

                                        <div className="row">


                                            <div className="col-md-12">
                                                <div className="form-group ">
                                                    <label className="contentLabel">Registered Address<span className="star-color"> &nbsp;*</span></label>
                                                    <input maxLength={100}  {...register("address", { required: true })} type="text" className="form-control" id="inputEmail4" placeholder="Enter registered address" />
                                                    {errors.address && (
                                                        <span className="errorMsg">
                                                            This is a required field
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="form-group ">
                                                    <label className="contentLabel">City<span className="star-color"> &nbsp;*</span></label>
                                                    {/* <select className="form-control "   {...register("city_id", {
                                                        required: true,
                                                        onChange: (e) => {

                                                            // console.log("city tyoe", city_type);
                                                            let filterData = city_type.filter((item) => item.id == e.target.value);
                                                            console.log("filter data", filterData);
                                                            setStateName(filterData[0]['state']['state_name'])
                                                            // stateName = filterData[0]['state']['state_name'];
                                                        }

                                                    })}>
                                                        <option value=' 'key='asd'>Please select</option>
                                                        {

                                                            city_type ? city_type.map((item) => (
                                                                <option value={item.id} key={item.id}>{item.city_name}</option>
                                                            )) : <option value=' 'key='asd'>Please select</option>



                                                        }
                                                    </select> */}

                                                    <Multiselect ref={citymultiselectRef} isObject selectionLimit='1' closeOnSelect={true} onSelect={(e) => {
                                                        console.log(e);
                                                        setStateName(e[0]['state']['state_name'])
                                                        let arry = []
                                                        e.map((item) => {
                                                            arry.push(item.id)
                                                        })

                                                        setCitySelect(arry);


                                                    }} options={city_type} displayValue="city_name" />
                                                    {errors.city_id && (
                                                        <span className="errorMsg">
                                                            This is a required field
                                                        </span>
                                                    )}

                                                </div>



                                                <div className="form-group ">
                                                    <label className="contentLabel">State </label>
                                                    <input type="text" readOnly value={stateName} className="form-control" id="inputEmail4" placeholder="State" />
                                                </div>

                                                <div className="form-group ">
                                                    <label className="contentLabel">Pincode </label>
                                                    <input maxLength={6} type="number"  {...register("pincode", {
                                                        required: false,

                                                    })} className="form-control" id="inputEmail4" placeholder="Enter Pincode" />
                                                </div>

                                                <div className="form-group ">
                                                    <label className="contentLabel">Webiste </label>
                                                    <input    {...register("website")} type="url" className="form-control" id="inputEmail4" placeholder="Enter website" />
                                                </div>



                                            </div>

                                        </div>


                                    </div>


                                </div>
                                <div className="row justify-content-center">

                                </div>
                                <br /><br />
                            </div>
                            <br /><br />
                        </div>


                        {/* owner details */}
                        <div className="col-md-12">
                            <br /><br />
                            <div className="form-section ">
                                <div className="row">
                                    <div className="col-md-12 p-5 pt-0 pb-0">
                                        <h3 className="title">Owner Details </h3>

                                        <div className="row">


                                            <div className="col-md-12">

                                                <div className="row ">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Owner Name<span className="star-color"> &nbsp;*</span></label>
                                                            <input maxLength={100}  {...register("name", {
                                                                required: true,
                                                                onBlur: (e) => {

                                                                    let Name = e.target.value.toUpperCase()
                                                                    let CName = Name.split(" ");

                                                                    setcOwnerName(CName)
                                                                },
                                                            })} type="text" className="form-control" id="inputEmail4" placeholder="Enter owner name" />
                                                            {errors.name && (
                                                                <span className="errorMsg">
                                                                    This is a required field
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Email Address</label>
                                                            <input    {...register("email_address")} type="email" className="form-control" id="inputEmail4" placeholder="Enter your email address." />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-md-3 multiRowowner">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Mobile No<span className="star-color"> &nbsp;*</span></label>
                                                            <input maxLength={10}   {...register("mobile_no", { required: true })} type="number" className="form-control" id="inputEmail4" placeholder="Enter your Mobile No." />
                                                            {errors.mobile_no && (
                                                                <span className="errorMsg">
                                                                    This is a required field
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 multiRowowner">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Date of Birth</label>
                                                            <input   {...register("dob")} type="date" className="form-control" id="inputEmail4" placeholder="Enter your DOB No." />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Whatsapp  No</label>
                                                            <input maxLength={10}  {...register("whats_app_no", {
                                                                required: false,
                                                                maxLength: {
                                                                    value: 10,
                                                                    message: "Enter 10  digit mobile number"
                                                                },
                                                                pattern: {
                                                                    value:
                                                                        /[3-9]\d\d/,
                                                                    message: "Enter valid mobile number",
                                                                },
                                                            })} type="number" className="form-control" id="inputEmail4" placeholder="Enter your Whatsapp No." />

                                                            {errors.whats_app_no && (
                                                                <span style={{ color: "red" }} role="alert">
                                                                    {errors.whats_app_no.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="row justify-content-center">

                                </div>
                                <br /><br />
                            </div>
                            <br /><br />
                        </div>

                        {/* other details */}
                        <div className="col-md-12">
                            <br /><br />
                            <div className="form-section ">
                                <div className="row">
                                    <div className="col-md-12 p-5 pt-0 pb-0">
                                        <h3 className="title">Other Details </h3>

                                        <div className="row">


                                            <div className="col-md-12">


                                                <div className="row ">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Specialisation<span className="star-color"> &nbsp;*</span></label>

                                                            <Multiselect ref={ProjectmultiselectRef} isObject value={project_type.id} onSelect={(e) => {
                                                                console.log("works", e);

                                                                let arry = []
                                                                e.map((item) => {
                                                                    arry.push(item.id)
                                                                })

                                                                setprojectTypeSelect(arry)

                                                                console.log("array list", arry);
                                                            }} options={project_type} displayValue="project_type_name" />
                                                            <br />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Unique Identification<span className="star-color"> &nbsp;*</span></label>
                                                            <select className="form-control"    {...register("unique_identification_id", { required: true })} >
                                                                <option value=' ' key='asd'>Please select</option>
                                                                {unique_identification ? unique_identification.map((item) => (
                                                                    <option value={item.id} key={item.id}>{item.unique_identification_name}</option>
                                                                )) : <option value=' ' key='asd'>Please select</option>
                                                                }
                                                            </select>
                                                            {errors.unique_identification_id && (
                                                                <span className="errorMsg">
                                                                    This is a required field
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row ">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Type of Contractor<span className="star-color"> &nbsp;*</span></label>
                                                            <select className="form-control"    {...register("type_of_contractor_id", { required: true })} >
                                                                <option value=' ' key='asd'>Please select</option>
                                                                {contractor_type ? contractor_type.map((item) => (
                                                                    <option value={item.id} key={item.id}>{item.material_type_name}</option>
                                                                )) : <option value=' ' key='asd'>Please select</option>
                                                                }
                                                            </select>
                                                            {errors.type_of_contractor_id && (
                                                                <span className="errorMsg">
                                                                    This is a required field
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">No of Projects Completed</label>
                                                            <input maxLength={3} {...register("no_of_projects_completed")} type="number" className="form-control" id="inputEmail4" placeholder="Enter No. of projects completed." />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">

                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Preferred Location<span className="star-color"> &nbsp;*</span></label>


                                                            <Multiselect ref={preferedtmultiselectRef} isObject closeOnSelect='true' onSelect={(e) => {
                                                                console.log("works", e);

                                                                let arry = []
                                                                e.map((item) => {
                                                                    arry.push(item.id)
                                                                })

                                                                setPreferdlocationSelect(arry)

                                                            }} options={city_type} displayValue="city_name" />
                                                            <br />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Geographical Spread</label>
                                                            <Multiselect ref={geotmultiselectRef} isObject closeOnSelect='true' onSelect={(e) => {
                                                                let arry = []
                                                                e.map((item) => {
                                                                    arry.push(item.id)
                                                                })

                                                                setGeolocationSelect(arry)

                                                            }} options={city_type} displayValue="city_name" />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Certificate</label>
                                                            <input maxLength={100}   {...register("certification")} type="text" className="form-control" id="inputEmail4" placeholder="Enter Certificate." />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="contentLabel">Description of Work</label>
                                                            <input maxLength={200}   {...register("description_of_work")} type="text" className="form-control" id="inputEmail4" placeholder="Enter Description of Work." />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>


                                    </div>


                                </div>
                                <div className="row justify-content-center">

                                </div>
                                <br /><br />
                            </div>
                            <br /><br />
                        </div>

                        {/* refrence details */}
                        <div className="col-md-12">
                            <br /><br />
                            <div className=" form-section ">
                                <div className="row">
                                    <div className="col-md-12 p-5 pt-0 pb-0">
                                        <h3 className="title">Reference 1 Details</h3>

                                        <div className="row">


                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <label className="contentLabel">Project Incharge Name</label>
                                                    <input maxLength={100}  {...register("PIName1")} type="text" className="form-control" id="inputEmail4" placeholder="Enter Project Incharge Name" />
                                                </div>
                                                <div className="form-group ">
                                                    <label className="contentLabel">Company name</label>
                                                    <input maxLength={100} {...register("Companyname1")} type="text" className="form-control" id="inputEmail4" placeholder="Enter Company name" />
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="contentLabel" >Project Name </label>
                                                    <input maxLength={100}  {...register("Projectname1")} type="text" className="form-control" id="inputPassword4" placeholder="Enter your Project Name" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="contentLabel" >Mobile Number </label>
                                                    <input maxLength={10}  {...register("mob1")} type="number" className="form-control" id="inputPassword4" placeholder="Enter your mobile number" />
                                                </div>
                                            </div>

                                        </div>


                                    </div>


                                </div>
                                <div className="row justify-content-center">

                                </div>
                                <br /><br />
                            </div>
                            <br /><br />
                        </div>
                        {/* refrence details */}
                        <div className="col-md-12">
                            <br /><br />
                            <div className=" form-section ">
                                <div className="row">
                                    <div className="col-md-12 p-5 pt-0 pb-0">
                                        <h3 className="title">Reference 2  Details</h3>

                                        <div className="row">


                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <label className="contentLabel">Project Incharge Name</label>
                                                    <input maxLength={100}  {...register("PIName2")} type="text" className="form-control" id="inputEmail4" placeholder="Enter Project Incharge Name" />
                                                </div>
                                                <div className="form-group ">
                                                    <label className="contentLabel">Company name</label>
                                                    <input maxLength={100}  {...register("Companyname2")} type="text" className="form-control" id="inputEmail4" placeholder="Enter Company name" />
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="contentLabel" >Project Name </label>
                                                    <input maxLength={100}   {...register("Projectname2")} type="text" className="form-control" id="inputPassword4" placeholder="Enter your Project Name" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="contentLabel" >Mobile Number </label>
                                                    <input maxLength={10}   {...register("mob2")} type="number" className="form-control" id="mobile_Number" placeholder="Enter your mobile number" />
                                                </div>
                                            </div>

                                            <div className="form-group" style={{ marginTop: '2%' }}>
                                                <div className="form-check">

                                                    <label className="termscondition form-check-label"  >
                                                        <input    {...register("tc_accepted", { required: true })} className="form-check-input" type="checkbox" id="gridCheck" />
                                                        I have read and accept the <a style={{ color: '#f47d35' }} href="#">terms and conditions</a> as well as <a style={{ color: '#f47d35' }} href="#">privacy policy</a> before submitting
                                                    </label><br />

                                                </div>
                                                <div className="form-check">

                                                    <label className="termscondition form-check-label"  >
                                                        <input    {...register("whatsapp_accepted")} className="form-check-input" type="checkbox" id="gridCheck" />
                                                        I agree to receive messages on WhatsApp  </label><br />

                                                </div>
                                            </div>
                                            <div className="row justify-content-center submit-btn">
                                                <button className=" btn ContactButton" type="submit" >Submit</button>
                                            </div>

                                        </div>


                                    </div>


                                </div>
                                <div className="row justify-content-center">

                                </div>
                                <br /><br />
                            </div>
                            <br /><br />
                        </div>



                    </div>
                </form>
            </div>


        </>)

}




// html desing



export default LCForm;