import React, { useState, useEffect } from "react";
import axios from "axios";
import '../styles/Career.css';
import { useForm } from "react-hook-form";
import Spinner from "../components/Spinner";

function Career() {
    const {
        register,
        handleSubmit, reset,
        formState: { errors },
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [role_type, setRole_type] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        roleType();
    }, []);

    // Fetch Role Types
    const roleType = async () => {
        try {
            const res = await axios.get("http://3.7.163.243:4100/role_type/get/");
            setRole_type(res.data.data.results);
        } catch (error) {
            console.error("Error fetching role types:", error);
        }
    };

    // Form Submit
    const onSubmitForm = (data) => {
        console.log("Form data:", data);
        setIsLoading(true)
        // Extract file details
        const file = data.cv[0]; // Get the uploaded file
        if (!file) {
            alert("Please upload a valid file");
            return;
        }

        // Create FormData object
        const formData = new FormData();
        formData.append("name", data.name.trim() + " " + data.lastName.trim());
        formData.append("email", data.email.trim());
        formData.append("mobile_number", data.mobile_number.trim());
        formData.append("job_title", data.job_title || "");
        formData.append("cv", file);

        console.log("Final FormData:", Object.fromEntries(formData.entries()));

        onSubmit(formData);
    };



    // Validation
    const validateData = (obj) => {
        if (!obj.name) {
            alert("Name should not be blank");
            return false;
        }
        if (!obj.email) {
            alert("Email should not be blank");
            return false;
        }
        if (!obj.mobile_number) {
            alert("Mobile number should not be blank");
            return false;
        }
        console.log("Form validated successfully");
        return true;
    };

    // Final Submit
    async function onSubmit(data) {
        console.log("Submitted data:", data);
        const submit = await axios.post("http://3.7.163.243:5000/email/job-apply/", data).then((res) => {
            console.log("api responser--", res)
            reset();
            setIsLoading(false)
            alert('You are succesfully registered..!')
        }).catch((err) => {
            setIsLoading(false)
            console.log("error--", err)
            alert("somthing went wrong please try again")
        });
    }

    return (
        <>

            {isLoading && <Spinner></Spinner>}
            {/* Page Heading */}
            <div className="container-fluid contactpageSection">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Contactheading">Career</h1>
                        <p className="subHeading">Reach us at helpdesk@cap-tech.in | Call us on 1800 266 7037.</p>
                    </div>
                </div>
            </div>

            {/* Contact Form */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <br /><br />
                        <div className="container form-section">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-11">
                                    <form onSubmit={handleSubmit(onSubmitForm)}>
                                        <div className="row">
                                            {/* Left Side Inputs */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="contentlable" htmlFor="firstName">First Name<span className="star-color">&nbsp;*</span></label>
                                                    <input type="text" className="form-control" id="firstName" {...register("name", { required: true })} placeholder="Enter First Name" />
                                                    {errors.name && <span className="errorMsg">This field is required</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label className="contentlable" htmlFor="email">Email Address<span className="star-color">&nbsp;*</span></label>
                                                    <input type="email" className="form-control" id="email" {...register("email", { required: true })} placeholder="Enter Email" />
                                                    {errors.email && <span className="errorMsg">This field is required</span>}
                                                </div>
                                                <div className="form-group">
                                                    <label className="contentlable" htmlFor="role">Role<span className="star-color">&nbsp;*</span></label>
                                                    <input type="text" className="form-control" id="role" {...register("job_title", { required: true })} placeholder="Enter Role" />
                                                    {errors.job_title && <span className="errorMsg">This field is required</span>}
                                                </div>

                                              
                                            </div>

                                            {/* Right Side Inputs */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="contentlable" htmlFor="lastName">Last Name<span className="star-color">&nbsp;*</span></label>
                                                    <input type="text" className="form-control" id="lastName" {...register("lastName", { required: true })} placeholder="Enter Last Name" />
                                                    {errors.lastName && <span className="errorMsg">This field is required</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label className="contentlable" htmlFor="mobile_number">Contact Number<span className="star-color">&nbsp;*</span></label>
                                                    <input type="number" className="form-control" id="mobile_number" {...register("mobile_number", { required: true })} placeholder="Enter Contact No" />
                                                    {errors.mobile_number && <span className="errorMsg">This field is required</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label className="contentlable" htmlFor="cv">Upload Resume<span className="star-color">&nbsp;*</span></label>
                                                    <input type="file" className="form-control" id="cv" {...register("cv", { required: true })} />
                                                    {errors.cv && <span className="errorMsg">This field is required</span>}
                                                </div>
                                            </div>

                                            {/* Submit Button */}
                                            <div className="row justify-content-center submit-btn">
                                                <button className="btn ContactButton" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <br /><br />
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Career;
