import React from "react";
import '../styles/Terms.css';


function Terms(){
    return(
        
        <>
               {/* sub heading */}
               <div className="container-fluid mediapageSection">
                <div className="row">
                    <div className="col-md-12">
                            <h1 className="Contactheading"> Terms and Conditions</h1>
                   </div>
                </div>
            </div>



            {/* Data */}

          
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <br></br>  <br></br>  <br></br>
                <h6>INTRODUCTION</h6><br></br>
                <ul>
                    <li>CAPTECH Technologies Private Limited’s website https://cap-tech.in and application known as eFORCE (hereinafter collectively referred to as the “Site") is owned and operated by CAPTECH Technologies Private Limited, a company registered in India. CAPTECH Technologies Private Limited and its, subsidiaries and affiliates are hereinafter collectively referred to as “CAPTECH”.</li>    
                    <br></br>
                    <li>The following Terms and Conditions, which include the Privacy Policy applicable to the User’s usage of and interaction with the Site, (hereinafter the “T’s & C’s”) govern the relationship between you and CAPTECH in the use of the Site. In case of User’s who may subscribe to the services offered by CAPTECH, additional terms and conditions will be made applicable in relation to the usage of such services offered to the Users by CAPTECH.</li>
                    <br></br>
                    <li>By using the Site, you are agreeing to comply with and be legally bound by these T’s & C’s and other terms and conditions which may be applicable in respect of the services, and you provide your express consent for your personal information to be dealt with in accordance with the terms of the Privacy Policy (“Privacy Policy”).</li>
                    <br></br>
                    <li>If you have any queries with regard to the Site or the security of this Site please contact CAPTECH by sending an email to helpdesk@cap-tech.in.</li>
                    <br></br>
                    <li>No opinions, research information, data or content contained on the Site (whether posted by CAPTECH or a 3rd party) should be construed as advice. Before making any decision or taking any action that might affect your business, you should seek specific, professional advice around your use of the Site, registration process and the CAPTECH software products and services.</li>
                    <br></br>
                    <li>CAPTECH will from time to time make changes to these T’s & C’s, and such changes will be posted to this page, as such it is your responsibility to periodically review this page in order to stay up to date on any modifications and/or amendments made by CAPTECH.</li>
                    <br></br>
                    <li>You acknowledge that you will be bound by these T’s and C’s for using the Site. If you do not agree with any part of the T’s and C’s, please do not use the Site or avail any services. Your access to use of the Site and the services will be solely at the discretion of CAPTECH. The T’s and C’s is published in compliance of, and is governed by the provisions of Indian law.</li>
                    <br></br>
                    <li>The information may contain technical inaccuracies or typographical errors. CAPTECH reserves the right to make changes, corrections and/or improvements to the information, and to the products and services described in such information, at any time without notice.</li>
                    <br></br>
                    <li>CAPTECH Site contains information on its services, not all of which are available in every location. A reference to CAPTECH service on the website does not imply that such product or service is or will be available in your location.</li>
                    <br></br>
                    <li>You must be 18 years of age or older to register, use the services, or visit or use or install or access the Site in any manner. By registering, visiting and using the Website or accepting this Agreement, you represent and warrant to CAPTECH that you are 18 years of age or older, and that you have the right, authority and capacity to use the Site and the services available through the Site, and agree to and abide by these T’s and C’s.</li>
                    <br></br>
                </ul>           
            </div>

            <div className="col-md-12">
                <br></br><br></br><br></br>
                <h6>CONTENT</h6><br></br>
                <ul>
                    <li>The Site aims to provide specific information regarding making the User aware of the services and products offered by CAPTECH and also to provide services through the Site to such Users who may have subscribed to the service on such terms and conditions separately agreed between the Parties.</li>
                    <br></br>
                    <li>These T’s and C’s do not purport to govern the whole relationship between you and CAPTECH. The relevant agreement will be concluded at the time of subscription of the specific CAPTECH software product/service and this agreement and the Service Level Agreement annexed to the specific CAPTECH software product/service will stipulate the roles and obligations of each party.</li>
                    <br></br>
                </ul>
            </div>


            <div className="col-md-12">
                <br></br><br></br><br></br>
                <h6>RESTRICTIONS ON USE</h6><br></br>
                <ul>
                   <li> You must not, other than as provided for in these T’s & C’s:</li><br></br>
                    <li>Remove any copyright, trademark or other intellectual property right notice contained in the Site;</li>
                           <br></br>
                  <li>Use the material from the Site in any manner or for any purpose which is unlawful or in any manner which violates any right of CAPTECH.</li>
                    <br></br>
                    <li>To the extent permitted by law, you hereby indemnify CAPTECH against any and all claims arising from the use by 3rd parties of any material from the Site that they have accessed as a result of your reproduction, redistribution, alteration or transmission of that material in contravention of these T’s & C’s.</li>
                    <br></br>
                </ul>
            </div>

            <div className="col-md-12">
                <br></br><br></br><br></br>
                <h6>REGISTRATION AND CANCELLATION
</h6><br></br>
                <ul>
                   <li>This Site is available free of charge, in order to access the Site you will be required to complete an account registration process and verify your contact details before you are allowed access to the Site.</li>
                    <br></br>
                    <li>When you register for access to the Site, you may be asked to provide certain personal details (“Personal Information”). CAPTECH will only use the Personal Information in accordance with the Privacy Policy.</li>
                    <br></br>
                    <li>CAPTECH uses cookies and similar technologies in our Websites and Services that help us collect other Information about you across other websites and online services.</li>
                    <br></br>
                    <li>CAPTECH reserves the right to terminate your registration to the Site and to restrict your access to the Site without notice should you at any time be in breach of these user T’s & C’s, any agreements concluded with CAPTECH and the Privacy Policy.</li>
                    <br></br>
                    <li>You may cancel your registration with the Site at any time by sending an email to helpdesk@cap-tech.in</li>
                    <br></br>
                    <li>Personal information will be deleted from CAPTECH systems within ninety (90) days of you cancelling your account with CAPTECH.</li>
                    <br></br>
                  </ul>
            </div>



        </div>
    </div>
        </>
    )
}


export default Terms;