import React, { useState, useEffect, lazy } from "react";
import "../styles/AboutUs.css";

import founderImg from '../assets/ashutosh.jpeg'
import ring from '../assets/about/ring.png'


function AboutUs() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            {/* sub heading */}
            <div className="container-fluid aboutpageSection">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="heading"> About Us </h1>
                    </div>
                </div>
            </div>


            {/* content section */}
            <div className="container ">
                <div className="row">
                    <div className="col-md-12 aboutUsContent">
                        <img src="/assets/capacite.png" className="border bg-white rounded-2 img-fluid" style={{ margin: '0 auto', display: 'block' }} alt="captech-about-logo" />
                        <p className="frist mt-3">“We are proud to be associate with CAPACITE INFRA PROJECTS LIMITED”</p>
                        <p className="frist"> Captech technologies pride itself by being deeply associated with the-Capacite group The group's flagship company Capacite's Infraprojects limited is a well-know and name in construction industry with sereval prestigious SUPER HIGH-RISE
                            STRUCTURES adorning thier portfolio
                        </p>
                        <p className=" frist website">Know more at- &nbsp;<span className="frist color"><a href="https://capacite.in/">www.capacite.in</a></span></p>
                    </div>

                    {/* <div className="col-md-12">
                        <h3 className="heading">Who we are</h3>

                        <p className="frist">“We are proud to be associate with CAPACITE INFRA PROJECTS LIMITED”</p>
        <p className="frist"> Captech technologies pride itself by being deeply associated with the-Capacite group The group's flagship company Capacite's Infraprojects limited is a well-know and name in construction industry with sereval prestigious SUPER HIGH-RISE
            STRUCTURES adorning thier portfolio
        </p>
          

                    </div> */}
                </div>
            </div>


            {/* our founder section */}
            <hr></hr>
            <div className="container-fluid">
                <div className="row founderSection">
                    <h2 className="founder2 mb-5">Our Founder</h2>
                    <div className="col-md-4" style={{ textAlign: "center" }}> <img className="img" src={founderImg} alt="laptop" /></div>
                    <div className="col-md-6 founderDetails">
                        <h3 style={{ color: '#f47d35' }}>Asutosh Katyal</h3>
                        <p>Founder And CEO</p>
                        <p>  Asutosh Katyal, Founder, and CEO, Captech Technologies Private Limited, is a computer scientist from the University of Illinois at Urbana Champaign. Prior to launching Captech Asutosh worked at Capacit’e Infraprojects limited on many marquee projects. His experience with the company helped him understand the operational difficulties on the ground in the construction business and it helped him lay the foundation of Captech.
                        </p>
                        {/* <button className="btn btn-light mainContentButton ">Know More</button> */}
                    </div>
                    <div className="col-md-4">
                        {/* <img className="img" src={ring} alt="laptop"/>               */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutUs;